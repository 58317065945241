<eplan-toolbar>
  <app-filters-panel
    [blade]="currentBlade"
    [datePeriodEnabled]="true"
    [exportEnabled]="true"
    [bookmarksEnabled]="true"
    [bookmarkNames]="[
      'dashboard',
      'Downloads',
      'Manufacturers',
      'Parts',
      'Users',
      'EDS'
    ]"
    (reportConfigChanged)="reportConfigChanged($event)"
  ></app-filters-panel>
</eplan-toolbar>
<eplan-content body>
  <div class="report-main-content" *ngIf="loaded">
    <app-reportviewer
      class="app-reportviewer"
      [blade]="currentBlade"
      [reportConfig]="reportConfig"
    ></app-reportviewer>
  </div>
</eplan-content>
