import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Pipe({
  name: "safeUrl"
})
export class SaveUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(content: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(content);
  }

}
