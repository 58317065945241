import { Injectable, OnDestroy } from "@angular/core";
import { State, BasicButtonState } from "@eplan/flux";
import { QueryManufacturer } from "app/models/queryManufacturer";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { Subscription, BehaviorSubject } from "rxjs";
import { HostService } from "../host.service";
import { QueryService } from "../query.service";
import { ReportsService } from "../reports.service";
import { ReportFilterService } from "app/models/reportFilters";
import { StorageService } from "../storage/storage.service";
import { StorageKeys } from "app/models/storageKeys";
import { ePLANReportCode } from "../../models/eplanReportCode";

export interface FavQueryManufacturer extends QueryManufacturer {
  favorite: boolean;
}

@Injectable({
  providedIn: "root"
})
export class ManufacturerFilterService implements OnDestroy, ReportFilterService {
  public defaultMan: QueryManufacturer | null | undefined;
  public cssCodes: Map<string, string> = new Map<string, string>();
  public manLogosUrl: SafeResourceUrl | undefined;
  public manEState: BasicButtonState = State.DEFAULT;
  private manufacturerSubscription: Subscription | undefined;
  private mans: FavQueryManufacturer[] = [];
  private categories: string[] = [];
  public selectedMan$: BehaviorSubject<QueryManufacturer | null> = new BehaviorSubject<QueryManufacturer | null>(null);
  public isSingle$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isMulti$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public favoriteMans$: BehaviorSubject<FavQueryManufacturer[]> = new BehaviorSubject<FavQueryManufacturer[]>([]);
  public nonFavoriteMans$: BehaviorSubject<FavQueryManufacturer[]> = new BehaviorSubject<FavQueryManufacturer[]>([]);

  constructor(
    public hostService: HostService,
    public reportsService: ReportsService,
    public queryService: QueryService,
    public sanitizer: DomSanitizer,
    private storageService: StorageService
  ) {
    const manufacturerLogosServer = this.hostService.getManufacturerLogosServer();
    const manLogosLocation = manufacturerLogosServer +
      (manufacturerLogosServer.length ? "/" : "") +
      "vimages/logos/css/sprites.css";
    this.manLogosUrl = this.sanitizer.bypassSecurityTrustResourceUrl(manLogosLocation);
    this.setCategories(this.categories);
  }

  public ngOnDestroy(): void {
    if (this.manufacturerSubscription) {
      this.manufacturerSubscription.unsubscribe();
    }
  }

  private getManufacturerLastSelected(): FavQueryManufacturer | null {
    return this.storageService.getDataFromStorage(StorageKeys.selectedManufacturersKey);
  }

  private isFavorite(man: QueryManufacturer, localFavorites: FavQueryManufacturer[]): boolean {
    return man.code === ePLANReportCode || (
      Array.isArray(localFavorites)
      && localFavorites.findIndex(item => item.code === man.code) !== -1);
  }

  public setCategories(categories: string[]) {
    if (this.manufacturerSubscription) {
      this.manufacturerSubscription.unsubscribe();
    }
    this.categories = categories;
    if (this.categories.length > 0) {
      this.manufacturerSubscription = this.queryService.getManufacturersList(this.categories.join())
        .subscribe(manufacturersList => {
          const favMansData = this.storageService.getDataFromStorage<FavQueryManufacturer[]>(StorageKeys.favoriteManufacturersKey);
          const localFavorites: FavQueryManufacturer[] = favMansData && favMansData.length ? favMansData : [];
          const manList: FavQueryManufacturer[] = [];
          if (manufacturersList) {
            manufacturersList.forEach(item => {
              manList.push({
                name: item.name,
                code: item.code,
                onboarded: item.onboarded,
                favorite: this.isFavorite(item, localFavorites),
              });
            });
          }
          this.mans = manList;
          this.updateFavoriteLists();
          this.changeManufacturerList();
        });
    }
    this.resetToDefault();
  }

  public changeManufacturerList(): void {
    this.resetToDefault();
    this.updateCssCodes(this.mans);
    this.isSingle$.next(this.selectedMan$.getValue() !== null && this.mans !== null && this.mans.length === 1);
    this.isMulti$.next(this.mans !== null && this.mans.length > 1);
  }

  public resetToDefault(keepLastSelection: boolean = true) {
    if (this.mans.length > 0) {
      const previous = this.getManufacturerLastSelected();
      if (keepLastSelection && previous !== null) {
        const item = this.mans.find(entry => entry.code === previous.code);
        if (item !== undefined) {
          this.selectedMan$.next(item);
        } else {
          this.selectedMan$.next(this.mans[0]);
        }
      } else {
        this.storageService.deleteDataFromStorage(StorageKeys.selectedManufacturersKey);
        this.selectedMan$.next(this.mans[0]);

      }
      this.defaultMan = this.mans[0];
    } else {
      this.selectedMan$.next(null);
      this.defaultMan = null;
    }
    this.updateColorScheme();
  }

  private updateCssCodes(mans: QueryManufacturer[]): void {
    mans.forEach(man => {
      this.cssCodes.set(man.code, this.reportsService.codeToCSS(man.code));
    });
  }

  public updateColorScheme(): void {
    const selected = this.selectedMan$.getValue();
    this.manEState = selected !== null && this.defaultMan !== null
      && selected.code !== this.defaultMan?.code ? State.PRIMARY : State.DEFAULT;
  }

  public onManSelected(man: FavQueryManufacturer): void {
    if (man) {
      this.storageService.saveDataToStorage(StorageKeys.selectedManufacturersKey, man);
      this.selectedMan$.next(man);
      this.updateColorScheme();
    }
  }

  public onStarSelected(man: FavQueryManufacturer): void {
    if (man.code === ePLANReportCode) {
      man.favorite = true;
    } else {
      man.favorite = !man.favorite;
      this.saveFavMansData();
    }
    this.updateFavoriteLists();
  }

  public updateFavoriteLists(): void {
    this.favoriteMans$.next(this.mans.filter(value => value.favorite).sort((a, b) =>
      a.code === ePLANReportCode ? -1 : b.code === ePLANReportCode ? 1 : a.name.localeCompare(b.name)));
    this.nonFavoriteMans$.next(this.mans.filter(value => !value.favorite)
      .sort((a, b) => a.name.localeCompare(b.name)));
  }

  public isChanged(): boolean {
    return this.defaultMan !== null && this.selectedMan$.getValue() !== null &&
      this.selectedMan$.getValue()?.code !== this.defaultMan?.code;
  }

  public getManufacturerName(code: string): string {
    const item = this.mans.find(entry => entry.code === code);
    if (item !== undefined) {
      return item.name;
    }

    return code;
  }

  private saveFavMansData(): void {
    this.storageService.saveDataToStorage(StorageKeys.favoriteManufacturersKey, this.mans.filter(value => value.favorite));
  }
}
