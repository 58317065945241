import { Injectable } from "@angular/core";
import { StorageKeys } from "app/models/storageKeys";

@Injectable({
  providedIn: "root"
})
export class StorageService {

  public getDataFromStorage<T>(key: StorageKeys): T | null {
    try {
      const itemString = localStorage.getItem(key);
      if (itemString !== null) {
        return JSON.parse(itemString);
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  public saveDataToStorage<T>(key: StorageKeys, data: T): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      // Unable to save data
    }
  }

  public deleteDataFromStorage(key: StorageKeys): void {
    localStorage.removeItem(key);
  }

}
