import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HostService } from "./host.service";
import { BackendEndpointsErrorHandler } from "app/services/backend-endpoints-error-handler.service";
import { Feature } from "app/models/feature";
import { BACKEND_API_PATH } from "app/models/api";

@Injectable()
export class FeaturesService {
  private reportsUrl: string;
  constructor(
    private httpClient: HttpClient,
    private hostService: HostService,
    private backendEndpointsErrorHandler: BackendEndpointsErrorHandler) {
    this.reportsUrl = this.hostService.getReportCenterServiceServer() + BACKEND_API_PATH;
  }

  public getAvailableFeatureByName(featureName: string): Observable<Feature> {
    const featureURL = `${this.reportsUrl}/available-feature-by-name/${featureName}`;
    return this.httpClient.get<Feature>(featureURL)
      .pipe(catchError(this.backendEndpointsErrorHandler.notificationHandler.bind(this.backendEndpointsErrorHandler)));
  }

  public getEmbedTrialFeature(): Observable<Feature> {
    return this.getAvailableFeatureByName("embedTrial");
  }
}
