import { Pipe, PipeTransform } from "@angular/core";
import { FavQueryManufacturer } from "app/services/filters/manufacturer-filter.service";
@Pipe({
  name: "mansfilter"
})
export class MansFilterPipe implements PipeTransform {
  public transform(items: FavQueryManufacturer[], searchText: string): FavQueryManufacturer[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();
    return items.filter(it => it.name.toLowerCase().includes(searchText));
  }
}
