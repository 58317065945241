<link
  *ngIf="manufacturerFilterService.manLogosUrl"
  rel="stylesheet"
  [href]="manufacturerFilterService.manLogosUrl"
/>
<eplan-toolbar>
  <app-filters-panel
    [blade]="Blade.Manufacturer"
    [tabBlades]="[Blade.Manufacturer]"
    [manufacturerEnabled]="true"
    [reportCategories]="reportCategories"
    [productGroupTreeEnabled]="true"
    [manufacturerCatalogEnabled]="true"
    [edsEnabled]="true"
    [datePeriodEnabled]="true"
    [searchEnabled]="true"
    [regionEnabled]="true"
    [exportEnabled]="true"
    (reportConfigChanged)="reportConfigChanged($event)"
  ></app-filters-panel>
</eplan-toolbar>
<eplan-content body>
  <div class="report-main-content" *ngIf="loaded">
    <app-reportviewer
      class="app-reportviewer"
      [blade]="Blade.Manufacturer"
      [reportConfig]="reportConfig"
    ></app-reportviewer>
  </div>
</eplan-content>
