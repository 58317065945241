// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { version } from "../app-version";

export const environment = {
  version,
  production: false,
  httpErrors: false,
  showReports: true,
  parallelReportLoader: false,
  onlyLastTwelveMonths: true,
  // backendApiUrl: "http://localhost:8080",
  // backendApiUrl: "https://reportcenterservicereview.dev.eplan.global",
  backendApiUrl: "",
  fallbackBackendApiUrl: "https://reportcenterservicereview.dev.eplan.global",
  fallbackLogosUrl: "https://reportcenterreview.dev.eplan.global",
  pbi: {
    filterPaneEnabled: true,
    navContentPaneEnabled: false,
    hideErrors: true
  },
  debug: {
    pbiClient: false,
    routerTracing: false,
    signalR: false,
    signalRDev: true,
    reloginTimeout: 30
  },
};
