export enum DataExportType {
  PDF,
  PPTX,
  CSV,
  DOCX,
  IMAGE,
  MHTML,
  PNG,
  XLSX,
  XML
}

export interface DataExportItem {
  nameID: string;
  extension: string;
  type: DataExportType;
}

const dataExports: DataExportItem[] = [
  {
    nameID: "DataExport.PDF",
    extension: "PDF",
    type: DataExportType.PDF
  },
  {
    nameID: "DataExport.PPTX",
    extension: "PPTX",
    type: DataExportType.PPTX
  },
  {
    nameID: "DataExport.CSV",
    extension: "CSV",
    type: DataExportType.CSV
  },
  {
    nameID: "DataExport.DOCX",
    extension: "DOCX",
    type: DataExportType.DOCX
  },
  {
    nameID: "DataExport.IMAGE",
    extension: "IMAGE",
    type: DataExportType.IMAGE
  },
  {
    nameID: "DataExport.MHTML",
    extension: "MHTML",
    type: DataExportType.MHTML
  },
  {
    nameID: "DataExport.PNG",
    extension: "PNG",
    type: DataExportType.PNG
  },
  {
    nameID: "DataExport.XLSX",
    extension: "XLSX",
    type: DataExportType.XLSX
  },
  {
    nameID: "DataExport.XML",
    extension: "XML",
    type: DataExportType.XML
  }
];

export { dataExports };
