import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateInitModule } from "@eplan/translateinit";
import { SaveUrlPipe } from "./pipes/safe-url.pipe";

@NgModule({
  declarations: [
    SaveUrlPipe
  ],
  imports: [
    CommonModule,
    TranslateInitModule
  ],
  exports: [
    CommonModule,
    TranslateInitModule,
    SaveUrlPipe
  ]
})
export class SharedModule { }
