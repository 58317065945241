import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { FavQueryManufacturer, ManufacturerFilterService } from "app/services/filters/manufacturer-filter.service";
import { Subscription, filter, skip } from "rxjs";
import { AuthorizationService } from "app/services/auth/authorization.service";
import { QueryManufacturer } from "app/models/queryManufacturer";

@Component({
  selector: "app-manufacturer-filter",
  templateUrl: "./manufacturer-filter.component.html",
  styleUrls: ["./manufacturer-filter.component.scss"]
})
export class ManufacturerFilterComponent implements OnInit, OnDestroy {

  @Input() set reportCategories(categories: string[]) {
    if (categories) {
      setTimeout(() => {
        this.manufacturerFilterService.setCategories(categories);
      }, 0);
    }
  }

  public text: string = "";
  private subscriptions = new Subscription();

  constructor(
    public manufacturerFilterService: ManufacturerFilterService,
    private authorizationService: AuthorizationService) { }

  public ngOnInit() {
    this.subscriptions.add(
      this.authorizationService.userData$.pipe(
        skip(1),
        filter(user => user?.organizationUserId !== undefined)
      ).subscribe(() => {
        this.manufacturerFilterService.resetToDefault();
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onManSelected(man: FavQueryManufacturer): void {
    if (man) {
      this.manufacturerFilterService.onManSelected(man);
    }
  }

  public onStarSelected(man: FavQueryManufacturer): void {
    if (man) {
      this.manufacturerFilterService.onStarSelected(man);
    }
  }

  public getManufacturerCssCode(queryManufacturer: QueryManufacturer | null) {
    return queryManufacturer ? "M-" + this.manufacturerFilterService.cssCodes.get(queryManufacturer.code) : "";
  }
}
