import { ExternalUser } from "app/models/externalUser";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HostService } from "./host.service";
import { BackendEndpointsErrorHandler } from "app/services/backend-endpoints-error-handler.service";
import { BACKEND_API_PATH } from "app/models/api";

const EXTERNAL_USER_API_PATH = BACKEND_API_PATH + "/external-user";

@Injectable()
export class ExternalUserService {
  private externalUserUrl: string;
  constructor(
    private httpClient: HttpClient,
    private hostService: HostService,
    private backendEndpointsErrorHandler: BackendEndpointsErrorHandler) {
    this.externalUserUrl = this.hostService.getReportCenterServiceServer() + EXTERNAL_USER_API_PATH;
  }

  public getExternalUser(): Observable<ExternalUser> {
    return this.httpClient.get<ExternalUser>(this.externalUserUrl)
      .pipe(catchError(this.backendEndpointsErrorHandler.skipHandler));
  }
}
