import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";
export const enum ReportPage {
  Home = "Page 1",
  Manufacturer = "Manufacturer",
  Users = "Users",
  Parts = "Parts"
}

export interface UserSelectedState {
  [ReportPage.Home]: boolean;
  [ReportPage.Manufacturer]: boolean;
  [ReportPage.Users]: boolean;
  [ReportPage.Parts]: boolean;
}

@Injectable({
  providedIn: "root"
})
export class PbiUserSelectedService {

  private userSelected: UserSelectedState = {
    [ReportPage.Home]: false,
    [ReportPage.Manufacturer]: false,
    [ReportPage.Parts]: false,
    [ReportPage.Users]: false
  };
  public dataSelectedState$: BehaviorSubject<UserSelectedState> = new BehaviorSubject<UserSelectedState>(this.userSelected);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setStateByData(data: any) {
    this.userSelected[data?.page?.displayName as keyof UserSelectedState] = data?.dataPoints?.length > 0;
    this.dispatch();
  }

  public setStateByPageName(pageName: ReportPage, state: boolean) {
    this.userSelected[pageName] = state;
  }

  private dispatch() {
    this.dataSelectedState$.next(Object.assign({}, this.userSelected));
  }

  public getStateUserSelected() {
    return Object.assign({}, this.userSelected);
  }
}
