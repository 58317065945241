import { Component, OnInit, OnDestroy } from "@angular/core";
import { State, BasicButtonState } from "@eplan/flux";
import { ManufacturerCatalogBatch, ManufacturerCatalogFilterService } from "app/services/filters/manufacturer-catalog-filter.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-manufacturer-catalog-filter",
  templateUrl: "./manufacturer-catalog-filter.component.html",
  styleUrls: ["./manufacturer-catalog-filter.component.scss"]
})
export class ManufacturerCatalogFilterComponent implements OnInit, OnDestroy {

  public eState: BasicButtonState = State.DEFAULT;

  private selectedGroupSubscription: Subscription | undefined;

  constructor(public manufacturerCatalogFilterService: ManufacturerCatalogFilterService) { }

  public ngOnInit() {
    this.selectedGroupSubscription = this.manufacturerCatalogFilterService.selectedGroup$.subscribe(
      selected => {
        this.updateColorScheme(selected);
      });
  }

  public ngOnDestroy(): void {
    this.selectedGroupSubscription?.unsubscribe();
  }

  public updateColorScheme(selected: ManufacturerCatalogBatch): void {
    this.eState = selected.filters.length > 0 ? State.PRIMARY : State.DEFAULT;
  }
}
