import { Injectable, ElementRef } from "@angular/core";
import { HeaderNotificationService } from "app/services/header-notification.service";
import { Subscription } from "rxjs";
import * as pbi from "powerbi-client";
import { timer } from "rxjs";
import { ReportsService } from "app/services/reports.service";
import { TranslateService } from "@ngx-translate/core";
import {
  ReportFilters, FilterDateType, DateRangeFilter, ProductGroupFilter, EDSFilter, PartFilter, RegionFilter,
  ManufacturerFilter, ReportConfig
}
  from "app/models/reportFilters";
import { environment } from "environments/environment";
import { getManufacturerCatalogLevels, ManufacturerCatalogFilter, MANUFACTURER_CATALOG_MAX_COLUMNS }
  from "app/models/filters/manufacturerCatalogFilter";
import { BehaviorSubject } from "rxjs";
import { IServiceConfiguration } from "service";
import { LoggerService } from "./logger.service";
import { PbiUserSelectedService } from "./filters/pbi-user-selected.service";
import { BookmarkMetadata } from "app/models/bookmarkMetadata";
import { DataExportItem } from "./data-export/DataExportType";
import { take } from "rxjs/operators";

const MILISECONDS_IN_SECOND = 1000;
const MILISECONDS_BEFORE_EXP = 60000;
const CUT_OFF_TEXT_WORKAROUND_ENABLED = true;
const BOOTSTRAP_ENABLED: boolean = false;
const MEASURE_TIME_REPORT_TO_USER: boolean = false;
const ACTIVITY_TIMEOUT_MS = 300000;
const RELOAD_TIMEOUT_MS = 5000;
const time = (label?: string) =>
  // eslint-disable-next-line no-console
  environment.debug.pbiClient ? console.time(label) : () => void 0;

const timeEnd = (label?: string) =>
  // eslint-disable-next-line no-console
  environment.debug.pbiClient ? console.timeEnd(label) : () => void 0;

export const VISUAL_TYPE_CR = "X-Type-CR";
export const VISUAL_TYPE_PG = "X-Type-PG";

export interface FilterState {
  type: string;
  enabled: boolean;
}

class VisualHolder {
  public isVisualSelected: boolean = false;
  private pbiReportService: PBIReportService;
  private types: Map<string, boolean>;
  private visuals: Map<string, string>;

  constructor(pbiReportService: PBIReportService) {
    this.pbiReportService = pbiReportService;
    this.types = new Map<string, boolean>();
    this.types.set(VISUAL_TYPE_CR, true).set(VISUAL_TYPE_PG, true);
    this.visuals = new Map<string, string>();
  }

  public reset(): void {
    this.visuals.clear();

    this.types.forEach((enabled, name) => {
      if (!enabled) {
        this.types.set(name, true);
        this.notifyFilterState(name, true);
      }
    });
  }

  public addVisual(visual: pbi.VisualDescriptor): void {
    if (this.types.has(visual.title)) {
      this.visuals.set(visual.name, visual.title);
    }
  }

  public handleDataSelected(selection: pbi.models.ISelection): void {
    if (selection.visual.name !== undefined) {
      this.isVisualSelected = true;

      if (this.visuals.has(selection.visual.name)) {
        const filtertype = this.visuals.get(selection.visual.name);

        if (!filtertype) { return; }

        const filterenabled = this.types.get(filtertype);
        const empty = selection.dataPoints.length === 0;

        if (!empty && filterenabled) {
          this.types.set(filtertype, false);
          this.notifyFilterState(filtertype, false);

          this.types.forEach((enabled, name) => {
            if (name !== filtertype && !enabled) {
              this.types.set(name, true);
              this.notifyFilterState(name, true);
            }
          });
        } else {
          if (empty && !filterenabled) {
            this.types.set(filtertype, true);
            this.notifyFilterState(filtertype, true);
          }
        }
      }
      else {
        this.enableAll();
      }
    }
  }

  private notifyFilterState(type: string, enabled: boolean): void {
    const fs: FilterState = { type, enabled };
    this.pbiReportService.filterState$.next(fs);
  }

  private enableAll(): void {
    this.types.forEach((enabled, name) => {
      if (!enabled) {
        this.types.set(name, true);
        this.pbiReportService.filterState$.next({ type: name, enabled: true });
      }
    });
  }
}

@Injectable()
export class PBIReportService {
  private loaded: boolean = false;
  private powerbi: pbi.service.Service;
  private embedReport: pbi.Report | undefined;
  private embedTokenRefresher: Subscription | undefined;
  private metadataSubscription: Subscription | undefined;
  private filters: pbi.models.IFilter[] = [];
  private timerId: null | ReturnType<typeof setTimeout> = null;
  private appliedFilters: boolean = false;
  private pages: pbi.Page[] = [];
  private lastPageName: string | null = null;
  private bookmarks: pbi.models.IReportBookmark[] = [];
  private lastBookmarkName: string | undefined;
  private lastState: BookmarkMetadata | undefined;
  private reportConfig: ReportConfig | null = null;
  private activityTimerId: null | ReturnType<typeof setTimeout> = null;
  private lastActivityNotificationTimestamp: number | undefined;
  private reloadTimerId: null | ReturnType<typeof setTimeout> = null;
  private exportInProgress: boolean = false;
  private visualHolder: VisualHolder;
  public bookmarksSubject$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public reportStatus$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public reportRequestor$: BehaviorSubject<DataExportItem | null> = new BehaviorSubject<DataExportItem | null>(null);
  public exportInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public filterState$: BehaviorSubject<FilterState | null> = new BehaviorSubject<FilterState | null>(null);

  constructor(
    private reportsService: ReportsService,
    private notificationService: HeaderNotificationService,
    private translateService: TranslateService,
    private loggerService: LoggerService,
    private pbiUserSelectedService: PbiUserSelectedService
  ) {
    this.visualHolder = new VisualHolder(this);

    const serviceConfiguration: IServiceConfiguration = {
      logMessages: environment.debug.pbiClient,
      onError: (error) => {
        loggerService.Error(error);
      }
    };
    this.powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory,
      serviceConfiguration);
  }

  public setReportConfig(reportConfig: ReportConfig) {
    this.reportConfig = reportConfig;
  }

  public getReportConfig(): ReportConfig | null {
    return this.reportConfig;
  }

  public setExportInProgress(inProgress: boolean): void {
    if (inProgress !== this.exportInProgress) {
      this.exportInProgress = inProgress;
      this.exportInProgress$.next(this.exportInProgress);
    }
  }

  public isExportInProgress(): boolean {
    return this.exportInProgress;
  }

  public hideReport(): void {
    this.lastPageName = null;
    this.pages = [];
    this.loaded = false;
    this.bookmarks = [];
    this.unsubscribeMetadataSubscription();
    if (this.embedTokenRefresher) {
      this.embedTokenRefresher.unsubscribe();
    }
    this.removeEmbedReportEventHandlers();
  }

  public bootstrap(reportID: string | undefined, reportContainer: ElementRef | undefined): void {
    if (MEASURE_TIME_REPORT_TO_USER) {
      time("reportToUser");
    }
    if (!reportID || !environment.showReports || !reportContainer) {
      return;
    }
    this.powerbi.reset(reportContainer.nativeElement);
    if (BOOTSTRAP_ENABLED) {
      const bootstrapConfig = {
        type: "report",
        hostname: "https://app.powerbi.com",
        settings: {
          filterPaneEnabled: environment.pbi.filterPaneEnabled,
          navContentPaneEnabled: environment.pbi.navContentPaneEnabled
        }
      };
      this.powerbi.bootstrap(reportContainer.nativeElement, bootstrapConfig);
    }
  }

  public showReport(reportID: string | undefined, reportContainer: ElementRef | undefined): void {
    if (!reportID || !environment.showReports || !reportContainer) {
      return;
    }

    if (this.reloadTimerId !== null) {
      clearTimeout(this.reloadTimerId);
      this.reloadTimerId = null;
    }

    this.metadataSubscription = this.reportsService.getReportMetadata(reportID).pipe(take(1))
      .subscribe(reportData => {
        if (reportData && this.reportConfig) {
          this.lastPageName = this.reportConfig.page ? this.reportConfig.page : null;
          this.setReportFilters(this.reportConfig.filters);
          const config = {
            type: "report",
            tokenType: pbi.models.TokenType.Embed,
            accessToken: reportData.embedToken,
            embedUrl: reportData.embedURL,
            id: reportData.id,
            permissions: pbi.models.Permissions.Read,
            settings: {
              filterPaneEnabled: environment.pbi.filterPaneEnabled,
              navContentPaneEnabled: environment.pbi.navContentPaneEnabled,
              hideErrors: environment.pbi.hideErrors,
              commands: [
                {
                  includeExclude: {
                    displayOption: pbi.models.CommandDisplayOption.Hidden,
                  },
                  exportData: {
                    displayOption: reportData.exportData ?
                      pbi.models.CommandDisplayOption.Enabled : pbi.models.CommandDisplayOption.Hidden,
                  },
                }
              ]
            },
            pageName: this.lastPageName,
            filters: this.getFilters()
          } as pbi.IEmbedConfiguration;
          this.appliedFilters = true;

          this.embedReport = this.powerbi.embed(reportContainer.nativeElement, config) as pbi.Report;
          this.handleActivity();

          this.removeEmbedReportEventHandlers();

          this.embedReport?.on("bookmarkApplied", (event: CustomEvent) => {
            this.handleActivity();
            if (this.bookmarks !== undefined && this.bookmarks.length > 0 && event.detail.bookmarkName !== undefined) {
              const set = new Set<string>(this.bookmarks.map(i => i.name));
              if (set.has(event.detail.bookmarkName)) {
                this.embedReport?.setFilters(this.getFilters());
              }
            }
          });

          this.embedReport?.on("loaded", () => {
            if (environment.debug.pbiClient) {
              this.loggerService.Debug("Loaded");
            }
            this.loaded = true;
            this.applyFilters();
            this.handleActivity();
            this.reportStatus$.next("loaded");

            this.embedReport?.getPages().then(pages => {
              this.pages = pages;
              if (this.lastPageName) {
                this.applyPage(this.lastPageName);
              }

              if (this.pages.length === 1) {
                this.lastPageName = this.pages[0].displayName;
              }

              this.updateVisuals();
            });

            this.embedReport?.bookmarksManager.getBookmarks().then(bookmarks => {
              this.bookmarks = bookmarks;
              const bookmarkNames = this.bookmarks.map(item => item.displayName);
              this.bookmarksSubject$.next(bookmarkNames);
            });
          });

          this.embedReport?.on("rendered", () => {
            if (environment.debug.pbiClient) {
              this.loggerService.Debug("Rendered");
            }
            this.handleActivity();
            this.reportStatus$.next("rendered");

            if (MEASURE_TIME_REPORT_TO_USER) {
              timeEnd("reportToUser");
            }
          });

          this.embedReport?.on("pageChanged", () => {
            this.handleActivity();
            this.updateVisuals();
          });
          this.embedReport?.on("error", (errorObject) => {
            const err = errorObject.detail as pbi.models.IError;

            if (err.message === pbi.models.CommonErrorCodes.FailedToLoadModel) {
              this.notificationService.info(Date.now().toString(),
                this.translateService.instant("PBI.reattempting"));

              this.reloadTimerId = setTimeout(
                this.showReport.bind(this, reportID, reportContainer), RELOAD_TIMEOUT_MS);
            } else {
              if (!environment.production) {
                const detailedMessage = err.detailedMessage !== undefined ? "(" + err.detailedMessage + ")" : "";
                this.notificationService.warn(Date.now().toString(),
                  this.translateService.instant("PBI.reportLoadingError") + err.message + detailedMessage);
              }
            }

            this.reportStatus$.next("error");
          });

          this.embedReport?.on("dataSelected", (event) => {
            this.embedReport?.bookmarksManager.capture().then(bookmark => {
              this.lastState = {
                name: bookmark.name,
                state: bookmark.state ?? ""
              };
            });

            const data = event.detail;
            this.pbiUserSelectedService.setStateByData(data);
            this.handleActivity();

            this.visualHolder.handleDataSelected(event.detail as pbi.models.ISelection);
          });
          this.embedReport?.on("commandTriggered", () => {
            this.handleActivity();
          });
          this.embedReport?.on("buttonClicked", () => {
            this.handleActivity();
          });
          this.embedReport?.on("filtersApplied", () => {
            this.handleActivity();
          });
          this.embedReport?.on("dataHyperlinkClicked", () => {
            this.handleActivity();
          });
          this.embedReport?.on("visualRendered", () => {
            this.handleActivity();
          });
          this.embedReport?.on("visualClicked", () => {
            this.handleActivity();
          });
          this.embedReport?.on("selectionChanged", () => {
            this.handleActivity();
          });

          this.refreshEmbedToken(reportID, reportData.expiresAt);
        } else {
          if (!environment.production) {
            this.notificationService.warn(Date.now().toString(),
              this.translateService.instant("PBI.noReportMetadata"));
          }
        }
      });
  }

  public applyBookmark(name: string | undefined): void {
    if (this.bookmarks && name) {
      this.bookmarks.forEach(item => {
        if (item.displayName === name) {
          this.embedReport?.bookmarksManager.apply(item.name);
          return;
        }
      });
      this.lastBookmarkName = name;
    }
  }

  public applyPage(name: string): void {
    if (this.pages) {
      this.pages.forEach(item => {
        if (item.displayName === name) {
          item.setActive();
          return;
        }
      });
      this.lastPageName = name;
    }
  }

  public getLastPageName(): string | null {
    return this.lastPageName;
  }

  public getLastPageSectionName(): string | undefined {
    if (this.lastPageName && this.pages) {
      for (const page of this.pages) {
        if (page.displayName === this.lastPageName) {
          return page.name;
        }
      }
    }

    return undefined;
  }

  public getLastBookmarkMetadata(): BookmarkMetadata | undefined {
    if (this.lastBookmarkName && this.bookmarks) {
      for (const bookmark of this.bookmarks) {
        if (bookmark.displayName === this.lastBookmarkName) {
          const bookmarkMetadata: BookmarkMetadata = {
            name: bookmark.name,
            state: bookmark.state ?? ""
          };

          return bookmarkMetadata;
        }
      }
    }

    return undefined;
  }

  public getCurrentStateBookmarkMetadata(): BookmarkMetadata | undefined {
    return this.lastState;
  }

  public requestReport(item: DataExportItem) {
    this.reportRequestor$.next(item);
  }

  public getReportId(): string | undefined {
    if (this.embedReport) {
      return this.embedReport.getId();
    }

    return undefined;
  }

  private updateVisuals() {
    this.visualHolder.reset();

    if (this.lastPageName === undefined) {
      return;
    }

    this.pages.forEach(page => {
      if (page.displayName === this.lastPageName) {
        page.getVisuals().then(visuals => {
          visuals.forEach(visual => {
            // Start of workaround for https://dev.azure.com/eplandev/EPLAN%20Cloud/_workitems/edit/132794
            if (CUT_OFF_TEXT_WORKAROUND_ENABLED && visual.layout.width && visual.layout.height) {
              if (visual.layout.height > 30) {
                visual.resizeVisual(visual.layout.width + 5.38, visual.layout.height + 5.38);
              } else {
                visual.resizeVisual(visual.layout.width, visual.layout.height + 2);
              }
            }
            // End of workaround

            this.visualHolder.addVisual(visual);
          });
        });
      }
    });
  }

  private getUserCulture(): string {
    const supportedCultures = ["en-US", "de-DE"]; // TODO: retrieve from BE
    let culture = "en_US";

    if (supportedCultures.includes(this.translateService.currentLang)) {
      culture = this.translateService.currentLang.replace("-", "_");
    }

    return culture;
  }

  private getCultureFilter(): pbi.models.IBasicFilter {
    const culture = this.getUserCulture();

    const cultureFilter: pbi.models.IBasicFilter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: "Locale",
        column: "Culture"
      },
      operator: "In",
      filterType: pbi.models.FilterType.Basic,
      values: [culture],
      requireSingleSelection: true
    };

    return cultureFilter;
  }

  public getFilters(): pbi.models.IFilter[] {
    const filters: pbi.models.IFilter[] = [];

    if (this.filters !== undefined) {
      this.filters.forEach((v) => filters.push(v));
    }

    filters.push(this.getCultureFilter());

    return filters;
  }

  public getManufacturerFilterCode(): string | null {
    return this.reportConfig?.filters?.manufacturer ? this.reportConfig.filters.manufacturer.code : null;
  }

  private removeEmbedReportEventHandlers(): void {
    if (this.embedReport !== undefined) {
      this.embedReport.off("loaded");
      this.embedReport.off("rendered");
      this.embedReport.off("error");
      this.embedReport.off("bookmarkApplied");
      this.embedReport.off("pageChanged");
      this.embedReport.off("dataSelected");
      this.embedReport.off("buttonClicked");
      this.embedReport.off("commandTriggered");
      this.embedReport.off("filtersApplied");
      this.embedReport.off("dataHyperlinkClicked");
      this.embedReport.off("visualRendered");
      this.embedReport.off("visualClicked");
      this.embedReport.off("selectionChanged");
    }
  }

  public handleActivity(): void {
    if (this.activityTimerId === null) {

      const timeNowMs = Date.now();
      let timeoutMs = ACTIVITY_TIMEOUT_MS;
      if (this.lastActivityNotificationTimestamp &&
        this.lastActivityNotificationTimestamp + ACTIVITY_TIMEOUT_MS > timeNowMs) {
        timeoutMs = ACTIVITY_TIMEOUT_MS - (timeNowMs - this.lastActivityNotificationTimestamp);
      } else {
        this.notifyActivity();
      }

      this.activityTimerId = setTimeout(() => {
        this.notifyActivity();
        this.activityTimerId = null;
      }, timeoutMs);
    }
  }

  private notifyActivity(): void {
    const reportID = this.getReportId();
    if (reportID) {
      this.reportsService.postReportActivity(reportID).pipe(take(1)).subscribe();
      this.lastActivityNotificationTimestamp = Date.now();
    }
  }

  private applyFilters() {
    if (this.embedReport !== undefined) {
      if (this.filters !== undefined) {
        if (!this.appliedFilters) {
          this.appliedFilters = true;
          this.embedReport.setFilters(this.getFilters()).catch(errors => {
            this.loggerService.Error(errors);
          });
        }
      } else {
        this.embedReport.setFilters([this.getCultureFilter()]).catch(errors => {
          this.loggerService.Error(errors);
        });
      }
    }

    if (this.visualHolder.isVisualSelected) {
      this.resetDisplayedPageVisuals();
    }
  }

  private resetDisplayedPageVisuals(): void {
    this.embedReport?.getPages().then(pages => {
      const displayedPage = pages.find(p => p.displayName === this.lastPageName);
      if (!displayedPage) {
        return;
      }
      this.setNewPageVisuals(displayedPage);
    });
  }

  private setNewPageVisuals(page: pbi.Page): void {
    this.visualHolder.reset();
    this.visualHolder.isVisualSelected = false;
    page.setActive();
    page.getVisuals().then(visuals => {
      visuals.forEach(visual => {
        this.visualHolder.addVisual(visual);
      });
    });
  }

  private toPBILocalDate(date: Date, from: boolean): string {
    if (from) {
      date.setHours(0, 0, 0, 0);
      return date.toISOString();
    } else {
      const HOURS = 23;
      const MINUTES = 59;
      const SECONDS = 59;
      const MILLISECONDS = 999;
      date.setHours(HOURS, MINUTES, SECONDS, MILLISECONDS);
      return date.toISOString();
    }
  }

  private getPBIDateFilter(dateRangeFilter: DateRangeFilter): pbi.models.IAdvancedFilter | null {
    let dateFilter: pbi.models.IAdvancedFilter | null = null;
    if (dateRangeFilter
      && dateRangeFilter.type !== FilterDateType.ALL
      && dateRangeFilter.range) {
      const fromDate = dateRangeFilter.range.from;
      const toDate = dateRangeFilter.range.to;
      const fromDateString = this.toPBILocalDate(fromDate, true);
      const toDateString = this.toPBILocalDate(toDate, false);
      dateFilter = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: "Date",
          column: "Date"
        },
        logicalOperator: "And",
        conditions: [
          {
            operator: "GreaterThanOrEqual",
            value: fromDateString
          },
          {
            operator: "LessThanOrEqual",
            value: toDateString
          }
        ],
        filterType: pbi.models.FilterType.Advanced,
        displaySettings: {
          isLockedInViewMode: false
        }
      };
    }

    return dateFilter;
  }

  private getPBIProductGroupFilterAll(
    productTopGroupFilters: ProductGroupFilter[],
    productGroupFilters: ProductGroupFilter[],
    idTopGetter: (m: ProductGroupFilter) => string,
    idGetter: (n: ProductGroupFilter) => string
  ): pbi.models.IAdvancedFilter | null {
    let pgFilter: pbi.models.IAdvancedFilter | null = null;
    if ((productTopGroupFilters && productTopGroupFilters.length > 0) || (productGroupFilters && productGroupFilters.length > 0)) {
      const conditions: pbi.models.IAdvancedFilterCondition[] = [];
      for (const filterPG of productGroupFilters) {
        conditions.push({
          operator: "Is",
          value: idGetter(filterPG)
        });
      }
      for (const filterPTG of productTopGroupFilters) {
        conditions.push({
          operator: "StartsWith",
          value: idTopGetter(filterPTG)
        });
      }
      pgFilter = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: "ProductGroupLang",
          column: "PG2Id"
        },
        logicalOperator: "Or",
        conditions,
        filterType: pbi.models.FilterType.Advanced,
        displaySettings: {
          isLockedInViewMode: false
        }
      };
    }
    return pgFilter;
  }

  private getManufacturerCatalogFilters(filters: ManufacturerCatalogFilter[]): pbi.models.IBasicFilter[] {
    const result: pbi.models.IBasicFilter[] = [];
    if (filters && filters.length > 0) {
      for (let i = 0; i < MANUFACTURER_CATALOG_MAX_COLUMNS; i++) {
        const values: string[] = getManufacturerCatalogLevels(filters, i);
        if (values.length > 0) {
          result.push({
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "ManufacturerCatalog",
              column: "Level" + i + "Id"
            },
            operator: "In",
            filterType: pbi.models.FilterType.Basic,
            values
          });
        }
      }
    }
    return result;
  }

  private getEDSFilter(filter: EDSFilter): pbi.models.IAdvancedFilter | null {
    let edsFilter: pbi.models.IAdvancedFilter | null = null;
    if (filter && filter.enabled) {
      edsFilter = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: "Part",
          column: "EDS"
        },
        logicalOperator: "And",
        conditions: [{
          operator: "IsNotBlank",
          value: ""
        }, {
          operator: "IsNot",
          value: "No data"
        },
        {
          operator: "IsNot",
          value: "Non-compliant"
        }],
        filterType: pbi.models.FilterType.Advanced,
        displaySettings: {
          isLockedInViewMode: false
        }
      };
    }
    return edsFilter;
  }

  private getPartsFilter(partFilter: PartFilter[]): pbi.models.IAdvancedFilter | null {
    let result: pbi.models.IAdvancedFilter | null = null;
    if (partFilter && partFilter.length > 0) {
      const conditions: pbi.models.IAdvancedFilterCondition[] = [];
      for (const filter of partFilter) {
        conditions.push({
          operator: "Is",
          value: filter.name
        });
      }
      result = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: "Part",
          column: "PartNr"
        },
        logicalOperator: "Or",
        conditions,
        filterType: pbi.models.FilterType.Advanced,
        displaySettings: {
          isLockedInViewMode: false
        }
      };
    }
    return result;
  }

  private getRegionsFilter(regionsFilter: RegionFilter[]): pbi.models.IBasicFilter[] {
    const result: pbi.models.IBasicFilter[] = [];
    if (regionsFilter && regionsFilter.length > 0) {
      const codes: string[] = [];
      for (const filter of regionsFilter) {
        for (const country of filter.countries) {
          codes.push(country.code);
        }
      }
      result.push({
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "Location",
          column: "Code"
        },
        operator: "In",
        filterType: pbi.models.FilterType.Basic,
        values: codes
      });
    }
    return result;
  }

  private getManufacturerFilter(manufacturerFilter: ManufacturerFilter): pbi.models.IAdvancedFilter | null {
    let result: pbi.models.IAdvancedFilter | null = null;
    if (manufacturerFilter && manufacturerFilter.code.length > 0) {
      result = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "Manufacturer",
          column: "ShortName"
        },
        logicalOperator: "And",
        conditions: [{
          operator: "Is",
          value: manufacturerFilter.code
        }],
        filterType: pbi.models.FilterType.Advanced,
        displaySettings: {
          isLockedInViewMode: false
        }
      };
    }
    return result;
  }

  private numberToStringWithPadding(n: number): string {
    const MAX_LEADING_ZEROS = 3;
    return n.toString().padStart(MAX_LEADING_ZEROS, "0");
  }

  // eslint-disable-next-line complexity
  public setReportFilters(reportFilters: ReportFilters | undefined): void {
    const modelFilters: pbi.models.IFilter[] = [];
    if (reportFilters) {
      const dateFilter: pbi.models.IAdvancedFilter | null
        = reportFilters.dateRange ? this.getPBIDateFilter(reportFilters.dateRange) : null;
      const productGroupsFilterAll: pbi.models.IAdvancedFilter | null
        = (reportFilters.genericProductGroups && reportFilters.productGroups)
          ? this.getPBIProductGroupFilterAll(reportFilters.genericProductGroups, reportFilters.productGroups,
            (filterPTG) => {
              const ptgID = parseInt(filterPTG?.ptgID ?? "", 10);
              if (isNaN(ptgID)) {
                this.loggerService.Error("ptgID error");
                return "";
              }
              return this.numberToStringWithPadding(ptgID);
            },
            (filterPG) => {
              const ptgID = parseInt(filterPG?.ptgID ?? "", 10);
              const pgID = parseInt(filterPG?.pgID ?? "", 10);
              if (isNaN(ptgID) || isNaN(pgID)) {
                this.loggerService.Error("ptgID/pgID error");
                return "";
              }
              return this.numberToStringWithPadding(ptgID) + this.numberToStringWithPadding(pgID);
            })
          : null;
      const manufacturerCatalogFilters: pbi.models.IBasicFilter[]
        = reportFilters.manufacturerCatalogFilters ? this.getManufacturerCatalogFilters(reportFilters.manufacturerCatalogFilters) : [];
      const edsFilter: pbi.models.IAdvancedFilter | null
        = reportFilters.eds ? this.getEDSFilter(reportFilters.eds) : null;
      const partsFilter: pbi.models.IAdvancedFilter | null
        = reportFilters.parts ? this.getPartsFilter(reportFilters.parts) : null;
      const regionsFilter: pbi.models.IBasicFilter[]
        = reportFilters.regions ? this.getRegionsFilter(reportFilters.regions) : [];
      const manufacturerFilter: pbi.models.IAdvancedFilter | null
        = reportFilters.manufacturer ? this.getManufacturerFilter(reportFilters.manufacturer) : null;

      if (dateFilter) {
        modelFilters.push(dateFilter);
      }
      if (productGroupsFilterAll) {
        modelFilters.push(productGroupsFilterAll);
      }
      manufacturerCatalogFilters.forEach(filter => modelFilters.push(filter));
      if (edsFilter) {
        modelFilters.push(edsFilter);
      }
      if (partsFilter) {
        modelFilters.push(partsFilter);
      }
      regionsFilter.forEach(filter => modelFilters.push(filter));
      if (manufacturerFilter) {
        modelFilters.push(manufacturerFilter);
      }
    }
    this.filters = modelFilters;
    this.appliedFilters = false;
    if (this.loaded && this.embedReport) {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(() => {
        this.applyFilters();
      });
    }
  }

  private unsubscribeMetadataSubscription(): void {
    if (this.metadataSubscription) {
      this.metadataSubscription.unsubscribe();
      this.metadataSubscription = undefined;
    }
  }

  private refreshEmbedToken(reportID: string, expiresAt: number) {
    const expiresAtInMs = expiresAt * MILISECONDS_IN_SECOND;
    let refreshTime = expiresAtInMs - MILISECONDS_BEFORE_EXP;
    refreshTime = refreshTime > Date.now() ? refreshTime : expiresAtInMs;
    this.embedTokenRefresher = timer(new Date(refreshTime)).subscribe(() => {
      this.reportsService.getReportMetadata(reportID).pipe(take(1)).subscribe(reportData => {
        if (reportData && this.embedReport) {
          this.embedReport.setAccessToken(reportData.embedToken);
          this.refreshEmbedToken(reportID, reportData.expiresAt);
        }
      });
    });
  }
}
