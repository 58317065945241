import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HostService } from "./host.service";
import { BackendEndpointsErrorHandler } from "app/services/backend-endpoints-error-handler.service";
import { EplanAnalyticsEvents } from "app/models/eplanAnalyticsEvents";
import { EplanAnalyticsUserAppliedFilterEvent, EplanAnalyticsUserAppliedFilterEventContext }
  from "app/models/eplanAnalyticsUserAppliedFilterEvent";
import { ReportFilters } from "app/models/reportFilters";
import { ManufacturerFilterService } from "app/services/filters/manufacturer-filter.service";
import { take } from "rxjs/operators";
import { BACKEND_API_PATH } from "app/models/api";

const EPLAN_ANALAYTICS_API_PATH = BACKEND_API_PATH + "/eplananalytics";

@Injectable()
export class EplanAnalyticsService {
  private eplanAnalyticsUrl: string;
  constructor(
    private httpClient: HttpClient,
    private hostService: HostService,
    private backendEndpointsErrorHandler: BackendEndpointsErrorHandler,
    private manufacturerFilterService: ManufacturerFilterService) {
    this.eplanAnalyticsUrl = this.hostService.getReportCenterServiceServer() + EPLAN_ANALAYTICS_API_PATH;
  }

  public sendEplanAnalyticsEvent(name: string, data?: unknown): Observable<void> {
    let body = new HttpParams().set("name", name);
    if (data !== undefined) {
      body = body.set("data", JSON.stringify(data));
    }

    return this.httpClient.post<void>(this.eplanAnalyticsUrl, body,
      { headers: { "Content-Type": "text/plain" } })
      .pipe(catchError(this.backendEndpointsErrorHandler.notificationHandler.bind(
        this.backendEndpointsErrorHandler)));
  }

  public sendEplanAnalyticsAppliedFilterEvent(
    context: EplanAnalyticsUserAppliedFilterEventContext, filters?: ReportFilters, manCode?: string): void {

    if (filters && manCode) {
      const eventData: EplanAnalyticsUserAppliedFilterEvent = {
        context,
        filters,
        manufacturer: this.manufacturerFilterService.getManufacturerName(manCode)
      };
      this.sendEplanAnalyticsEvent(EplanAnalyticsEvents.USER_APPLIED_FILTER_EVENT, eventData).pipe(take(1)).subscribe();
    }


  }
}
