import { OnDestroy, Injectable, Inject } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { FilterAction, ReportFilterService } from "app/models/reportFilters";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "../storage/storage.service";
import { finalize } from "rxjs/operators";
import { ManufacturerFilterService } from "./manufacturer-filter.service";
import { QueryManufacturer } from "app/models/queryManufacturer";
import { StorageKeys } from "app/models/storageKeys";
import { CatalogElement } from "app/models/filters/catalogElement";

export interface CheckedDrillElement<T> {
  id: string;
  translateId?: string;
  parent: CheckedDrillElement<T> | null;
  children?: CheckedDrillElement<T>[];
  hasChildren: boolean | undefined;
  checked: boolean | undefined;
  action: FilterAction;
  level: number;
  filter?: T;
  folded?: T[];
}

export interface CatalogDrillHandler<T extends CatalogElement> {
  getCatalog: (language: string, manufacturerShortName: string | null, levels: string[]) => Observable<T[]>;
  buildLevelArray: (element: T) => string[];
  compareCatalogs: (level: number, element1: T, element2: T) => boolean;
  getManufacturerCatalogLevel: (element: T) => number;
  isCatalogSublevel: (element1: T, element2: T) => boolean;
}

export interface ManufacturerCatalogStorageEntry<T extends CatalogElement> {
  items: T[];
  manufacturer?: string;
}
@Injectable()
export abstract class CatalogDrillService<T extends CatalogElement>
  implements OnDestroy, ReportFilterService {
  public currentItems: CheckedDrillElement<T>[] = [];
  public currentParent: CheckedDrillElement<T> | null = null;
  public isAvailable: boolean = false;

  protected root: CheckedDrillElement<T> = {
    id: "root",
    translateId: "",
    parent: null,
    hasChildren: true,
    children: [],
    action: FilterAction.SELECT_ITEM,
    checked: false,
    level: -1
  };

  private level0Subscription: Subscription | undefined;
  private languageSubscription: Subscription | undefined;
  private manufacturerSubscription: Subscription | undefined;
  private language: string;
  private selectedManufacturer: QueryManufacturer | undefined;

  constructor(
    protected translateService: TranslateService,
    protected storageService: StorageService,
    protected manufacturerFilterService: ManufacturerFilterService | null,
    private storageKey: StorageKeys,
    @Inject(String) private catalogMaxColumns: number,
    @Inject(String) private handler: CatalogDrillHandler<T>
  ) {
    this.language = this.formatLang(this.translateService.currentLang);
    this.languageSubscription = this.translateService.onLangChange.subscribe(event => {
      this.language = this.formatLang(event.lang);
      this.onLanguageChange();
    });
    if (manufacturerFilterService) {
      this.manufacturerSubscription = this.manufacturerFilterService?.selectedMan$.subscribe(
        selected => {
          if (this.selectedManufacturer !== selected && selected != null) {
            this.selectedManufacturer = selected;
            this.isAvailable = false;
            this.subscribeFirstLevel();
          }
        });
    } else {
      this.subscribeFirstLevel();
    }
    this.nextSelectedGroup(this.getSelectedFilters());
  }

  protected abstract nextSelectedGroup(selected: T[]): void;

  private buildCheckedFilters(list: T[], element: CheckedDrillElement<T>) {
    if (element.children) {
      element.children.forEach(item => {
        this.buildCheckedFilters(list, item);
        if (item.checked && item.action === FilterAction.SELECT_ITEM && item.filter) {
          list.push(item.filter);
        }
      });
    }
  }

  private collectFoldedElements(list: T[], element: CheckedDrillElement<T>) {
    if (element.children) {
      element.children.forEach(item => {
        if (item.folded) {
          this.collectFoldedElements(list, item);
          item.folded.forEach(filter => {
            if (item.filter && this.handler.isCatalogSublevel(item.filter, filter)) {
              list.push(filter);
            }
          });
        }
      });
    }
  }

  public getSelectedFilters(): T[] {
    const result: T[] = [];
    this.buildCheckedFilters(result, this.root);
    this.collectFoldedElements(result, this.root);
    return result;
  }

  private saveFilters(): void {
    const entry: ManufacturerCatalogStorageEntry<T> = this.selectedManufacturer ? {
      manufacturer: this.selectedManufacturer.code,
      items: this.getSelectedFilters()
    } : {
      items: this.getSelectedFilters()
    };
    this.storageService.saveDataToStorage<ManufacturerCatalogStorageEntry<T>>(this.storageKey, entry);
  }

  public getSelectAll(items: CheckedDrillElement<T>[]): CheckedDrillElement<T> | undefined {
    return items.find(item => item.action === FilterAction.SELECT_ALL);
  }

  public setAncestors(element: CheckedDrillElement<T>) {
    let next = element.parent;
    while (next && next.children) {
      next.checked = this.isParentChecked(next.children);
      next = next.parent;
    }
  }

  public setDescendants(element: CheckedDrillElement<T>, checked: boolean) {
    if (element.children) {
      element.children.forEach(item => {
        item.checked = checked;
        if (item.children && item.children.length > 0) {
          this.setDescendants(item, checked);
        }
      });
    }
  }

  public clearFoldedElements(element: CheckedDrillElement<T>) {
    if (element.children) {
      element.children.forEach(item => {
        item.folded = [];
        if (item.children && item.children.length > 0) {
          this.clearFoldedElements(item);
        }
      });
    }
  }

  private isParentChecked(items: CheckedDrillElement<T>[]) {
    return items.some(
      item => item.checked && item.action === FilterAction.SELECT_ITEM)
      ? items.every(
        item => (item.checked || item.action === FilterAction.SELECT_ALL))
        ? true : undefined
      : items.some(
        item => item.checked === undefined && item.action === FilterAction.SELECT_ITEM)
        ? undefined : false;
  }

  public updateSelectAll(element: CheckedDrillElement<T>, childrenFromParent: boolean) {
    let items: CheckedDrillElement<T>[] = [];
    if (childrenFromParent) {
      if (element.parent && element.parent.children) {
        items = element.parent.children;
      }
    } else {
      if (element.children) {
        items = element.children;
      }
    }
    const selectAll = items.length === 0 ? this.getSelectAll(items) : null;
    if (selectAll) {
      selectAll.checked = this.isParentChecked(items);
    }
  }

  private updateSelectAllAncestors(element: CheckedDrillElement<T>, childrenFromParent: boolean) {
    let next: CheckedDrillElement<T> | null | undefined = element;
    while (next) {
      this.updateSelectAll(next, childrenFromParent);
      next = next.parent;
    }
  }

  public onItemChanged(element: CheckedDrillElement<T>) {
    if (element.action === FilterAction.SELECT_ALL) {
      if (element.parent && element.parent.children) {
        element.parent.children.forEach(item => {
          item.checked = element.checked;
          this.setDescendants(item, false);
        });
        this.clearFoldedElements(element.parent);
      }
      this.setAncestors(element);
    } else {
      this.setAncestors(element);
      this.setDescendants(element, false);
      if (element.parent) {
        this.clearFoldedElements(element.parent);
      }
    }
    this.updateSelectAllAncestors(element, true);
    this.nextSelectedGroup(this.getSelectedFilters());
    this.saveFilters();
  }

  public goToParentDirectory(element: CheckedDrillElement<T>) {
    if (element.parent) {
      this.currentItems = element.parent.children ? element.parent.children : [];
      this.currentParent = element.parent === this.root ? null : element.parent;
    }
  }

  public filterLevelChecker(level: number, filter: T, element: CheckedDrillElement<T>): boolean | undefined {
    let result: boolean | undefined;
    if (element.folded) {
      result = element.folded.some(
        item => (this.handler.compareCatalogs(this.catalogMaxColumns, filter, item)))
        ? true
        : element.folded.some(item => (this.handler.compareCatalogs(level, filter, item))) ? undefined : false;
    } else {
      result = false;
    }
    return result;
  }

  public filterLevelFoldedElements(filter: T, element: CheckedDrillElement<T>): T[] {
    let result: T[] = [];
    if (element.folded) {
      result = element.folded.filter(item => (this.handler.getManufacturerCatalogLevel(filter)
        < this.handler.getManufacturerCatalogLevel(item)));
    }
    return result;
  }

  public buildLevel(element: CheckedDrillElement<T>, entries: T[]): CheckedDrillElement<T>[] {
    const result: CheckedDrillElement<T>[] = [];
    const newLevel = element.level + 1;
    if (entries.length > 0) {
      const selectAllItem: CheckedDrillElement<T> = {
        id: this.translateService.instant("Filters.SelectAll"),
        translateId: this.translateService.instant("Filters.SelectAll"),
        action: FilterAction.SELECT_ALL,
        level: newLevel,
        parent: element,
        hasChildren: false,
        checked: false
      };
      result.push(selectAllItem);
    }
    entries.forEach(item => result.push({
      id: item.name,
      translateId: item.name,
      hasChildren: (item.leaf === undefined && (newLevel + 1) < this.catalogMaxColumns),
      parent: element,
      action: FilterAction.SELECT_ITEM,
      checked: this.filterLevelChecker(newLevel, item, element),
      level: newLevel,
      filter: item,
      folded: this.filterLevelFoldedElements(item, element)
    }));
    element.folded = [];
    element.children = result;
    this.updateSelectAllAncestors(element, false);
    return result;
  }

  public goToDirectory(element: CheckedDrillElement<T>) {
    if (element.children === undefined) {
      const subscription: Subscription = this.handler.getCatalog(this.language,
        this.selectedManufacturer ? this.selectedManufacturer.code : null,
        element?.filter ? this.handler.buildLevelArray(element.filter) : [])
        .pipe(
          finalize(() => subscription.unsubscribe())
        ).subscribe(entries => {
          const items = this.buildLevel(element, entries);
          this.currentParent = element;
          this.currentItems = items;
        });
    } else {
      this.currentParent = element;
      this.currentItems = element.children;
    }
  }

  public isModified() {
    return this.root.children && !this.root.children.every(item => item.checked === true)
      && this.root.children.some(item => item.checked === true);
  }

  public ngOnDestroy() {
    if (this.level0Subscription) {
      this.level0Subscription.unsubscribe();
    }
    if (this.manufacturerSubscription) {
      this.manufacturerSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  private formatLang(lang: string) {
    return lang.replace("-", "_");
  }

  public clearSelection(): void {
    this.storageService.deleteDataFromStorage(this.storageKey);
    this.setDescendants(this.root, false);
    this.clearFoldedElements(this.root);
    this.nextSelectedGroup([]);
  }

  public isChanged() {
    return this.getSelectedFilters().length > 0;
  }

  public extractFolded(storedEntry: ManufacturerCatalogStorageEntry<T>): T[] {
    let match: boolean = false;
    if (storedEntry) {
      if (storedEntry.manufacturer !== undefined) {
        match = this.selectedManufacturer?.code === storedEntry.manufacturer;
      } else {
        match = this.selectedManufacturer === undefined;
      }
    }
    return match ? storedEntry.items : [];
  }

  public subscribeFirstLevel(): void {
    if (this.level0Subscription) {
      this.level0Subscription.unsubscribe();
    }
    this.level0Subscription = this.handler.getCatalog(this.language,
      this.selectedManufacturer?.code ? this.selectedManufacturer.code : null, []).subscribe(
        entries => {
          const storedEntry = this.storageService.getDataFromStorage<ManufacturerCatalogStorageEntry<T>>(
            this.storageKey);
          this.root.folded = storedEntry ? this.extractFolded(storedEntry) : undefined;
          const items = this.buildLevel(this.root, entries);
          this.root.children = items;
          this.currentParent = null;
          this.currentItems = items;
          if (items && items.length > 0) {
            this.isAvailable = true;
          }
          this.nextSelectedGroup(this.getSelectedFilters());
        });
  }

  private onLanguageChange() {
    this.root.children = [];
    this.subscribeFirstLevel();
  }
}
