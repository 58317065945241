import { CatalogElement, Levels } from "./catalogElement";

export const MANUFACTURER_CATALOG_MAX_COLUMNS = 10;

export interface ManufacturerCatalogFilter extends CatalogElement {
  level0ID?: string;
  level1ID?: string;
  level2ID?: string;
  level3ID?: string;
  level4ID?: string;
  level5ID?: string;
  level6ID?: string;
  level7ID?: string;
  level8ID?: string;
  level9ID?: string;
}

export const getManufacturerCatalogLevels = (filters: ManufacturerCatalogFilter[], level: number) => {
  const result: string[] = [];
  // eslint-disable-next-line complexity
  filters.forEach(filter => {
    if (level === Levels.L0 && filter.level0ID && filter.level1ID === undefined) {
      result.push(filter.level0ID);
    }
    if (level === Levels.L1 && filter.level1ID && filter.level2ID === undefined) {
      result.push(filter.level1ID);
    }
    if (level === Levels.L2 && filter.level2ID && filter.level3ID === undefined) {
      result.push(filter.level2ID);
    }
    if (level === Levels.L3 && filter.level3ID && filter.level4ID === undefined) {
      result.push(filter.level3ID);
    }
    if (level === Levels.L4 && filter.level4ID && filter.level5ID === undefined) {
      result.push(filter.level4ID);
    }
    if (level === Levels.L5 && filter.level5ID && filter.level6ID === undefined) {
      result.push(filter.level5ID);
    }
    if (level === Levels.L6 && filter.level6ID && filter.level7ID === undefined) {
      result.push(filter.level6ID);
    }
    if (level === Levels.L7 && filter.level7ID && filter.level8ID === undefined) {
      result.push(filter.level7ID);
    }
    if (level === Levels.L8 && filter.level8ID && filter.level9ID === undefined) {
      result.push(filter.level8ID);
    }
    if (level === Levels.L9 && filter.level9ID) {
      result.push(filter.level9ID);
    }
  });
  return result;
};

export const getManufacturerCatalogLevel = (entry: ManufacturerCatalogFilter): number => {
  let result: number = 0;
  if (entry.level0ID) {
    result = Levels.L0;
    if (entry.level1ID) {
      result = Levels.L1;
      if (entry.level2ID) {
        result = Levels.L2;
        if (entry.level3ID) {
          result = Levels.L3;
          if (entry.level4ID) {
            result = Levels.L4;
            if (entry.level5ID) {
              result = Levels.L5;
              if (entry.level6ID) {
                result = Levels.L6;
                if (entry.level7ID) {
                  result = Levels.L7;
                  if (entry.level8ID) {
                    result = Levels.L8;
                    if (entry.level9ID) {
                      result = Levels.L9;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return result;
};

// eslint-disable-next-line complexity
export const compareCatalogs = (level: number, a1: ManufacturerCatalogFilter, a2: ManufacturerCatalogFilter): boolean => {
  let result: boolean = true;
  if (level >= Levels.L0) {
    result = result && a1.level0ID === a2.level0ID;
    if (level >= Levels.L1) {
      result = result && a1.level1ID === a2.level1ID;
      if (level >= Levels.L2) {
        result = result && a1.level2ID === a2.level2ID;
        if (level >= Levels.L3) {
          result = result && a1.level3ID === a2.level3ID;
          if (level >= Levels.L4) {
            result = result && a1.level4ID === a2.level4ID;
            if (level >= Levels.L5) {
              result = result && a1.level5ID === a2.level5ID;
              if (level >= Levels.L6) {
                result = result && a1.level6ID === a2.level6ID;
                if (level >= Levels.L7) {
                  result = result && a1.level7ID === a2.level7ID;
                  if (level >= Levels.L8) {
                    result = result && a1.level8ID === a2.level8ID;
                    if (level >= Levels.L9) {
                      result = result && a1.level9ID === a2.level9ID;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return result;
};

export const buildLevelArray = (entry: ManufacturerCatalogFilter): string[] => {
  const result: string[] = [];
  if (entry.level0ID) {
    result.push(entry.level0ID);
    if (entry.level1ID) {
      result.push(entry.level1ID);
      if (entry.level2ID) {
        result.push(entry.level2ID);
        if (entry.level3ID) {
          result.push(entry.level3ID);
          if (entry.level4ID) {
            result.push(entry.level4ID);
            if (entry.level5ID) {
              result.push(entry.level5ID);
              if (entry.level6ID) {
                result.push(entry.level6ID);
                if (entry.level7ID) {
                  result.push(entry.level7ID);
                  if (entry.level8ID) {
                    result.push(entry.level8ID);
                    if (entry.level9ID) {
                      result.push(entry.level9ID);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return result;
};

/**
 * Checks if manufacturer catalog element1 is a sublevel of element2.
 *
 * Empty level ID is treated as undefined.
 *
 * @example
 * Result 'true':
 * element1: A, B, C, D, E
 * element2: A, B, C, D, E, F, G, H
 *
 * Result 'false':
 * element1: A, B, C, D, E
 * element2: A, B, C
 */
export const isCatalogSublevel = (element1?: ManufacturerCatalogFilter, element2?: ManufacturerCatalogFilter): boolean => {
  if (!element1 || !element2 || !element1.level0ID) {
    return false;
  }

  for (let level = 0; level <= Levels.L9; level++) {
    const key = `level${level}ID`;
    const levelElement1 = element1[key as keyof ManufacturerCatalogFilter];
    const levelElement2 = element2[key as keyof ManufacturerCatalogFilter];

    if (levelElement1 !== levelElement2) {
      return !levelElement1 && !!levelElement2;
    }
  }

  return false;
};
