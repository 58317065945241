import { Injectable } from "@angular/core";
import { AlertService , Notification as FluxNotification, State } from "@eplan/flux";
import { OneHeaderService, Notification, NotificationType } from "@eplan/oneheader";
@Injectable({
  providedIn: "root"
})
export class HeaderNotificationService {
  constructor(
    private alertService: AlertService,
    private oneHeaderService: OneHeaderService
  ) {
  }

  public success(id: string, message: string) {
    const fluxNotification = new FluxNotification();
    fluxNotification.message = message;
    fluxNotification.type = State.SUCCESS;
    this.alertService.showNotification(fluxNotification);

    let newNotification = false;
    let notification = this.oneHeaderService.getNotification(id);
    if (notification == null) {
      notification = new Notification();
      notification.id = id;
      newNotification = true;
    }

    notification.message = message;
    notification.type = NotificationType.Success;
    notification.created = new Date();

    if (newNotification) {
      this.oneHeaderService.addNotification(notification);
    } else {
      this.oneHeaderService.updateNotification(notification);
    }
  }

  public info(id: string, message: string) {
    const fluxNotification = new FluxNotification();
    fluxNotification.message = message;
    fluxNotification.type = State.INFO;
    this.alertService.showNotification(fluxNotification);

    let newNotification = false;
    let notification = this.oneHeaderService.getNotification(id);
    if (notification == null) {
      notification = new Notification();
      notification.id = id;
      newNotification = true;
    }

    notification.message = message;
    notification.type = NotificationType.Info;
    notification.created = new Date();

    if (newNotification) {
      this.oneHeaderService.addNotification(notification);
    } else {
      this.oneHeaderService.updateNotification(notification);
    }
  }

  public warn(id: string, message: string) {
    const fluxNotification = new FluxNotification();
    fluxNotification.message = message;
    fluxNotification.type = State.WARNING;
    this.alertService.showNotification(fluxNotification);

    let newNotification = false;
    let notification = this.oneHeaderService.getNotification(id);
    if (notification == null) {
      notification = new Notification();
      notification.id = id;
      newNotification = true;
    }

    notification.message = message;
    notification.type = NotificationType.Warning;
    notification.created = new Date();

    if (newNotification) {
      this.oneHeaderService.addNotification(notification);
    } else {
      this.oneHeaderService.updateNotification(notification);
    }
  }

  public error(id: string, message: string) {
    const fluxNotification = new FluxNotification();
    fluxNotification.message = message;
    fluxNotification.type = State.DANGER;
    this.alertService.showNotification(fluxNotification);

    let newNotification = false;
    let notification = this.oneHeaderService.getNotification(id);
    if (notification == null) {
      notification = new Notification();
      notification.id = id;
      newNotification = true;
    }

    notification.message = message;
    notification.type = NotificationType.Danger;
    notification.created = new Date();

    if (newNotification) {
      this.oneHeaderService.addNotification(notification);
    } else {
      this.oneHeaderService.updateNotification(notification);
    }
  }
}
