<eplan-layout>
  <one-header
    eplanLayoutHeader
    [fullScreenMode]="(showHeader$ | async) === false"
    [menuData]="menuStructure"
    [showCart]="false"
    [showLogo]="true"
  ></one-header>
  <eplan-master-detail eplanLayoutBody>
    <eplan-nav-bar
      eplanMasterDetailLeft
      *ngIf="authorizationService.isUserAuthorized$ | async"
    >
      <eplan-bar-item
        *eplanBarItem
        eIcon="ti-home"
        routerLink="/home"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
      >{{ "NavigationBar.Home" | translate }}
      </eplan-bar-item>
      <eplan-bar-item
        *eplanBarItem
        eIcon="fl-icon-manufacturer"
        routerLink="/manufacturer"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
      >{{ "NavigationBar.Manufacturer" | translate }}
      </eplan-bar-item>
      <eplan-bar-item
        *eplanBarItem
        eIcon="ti-package"
        routerLink="/parts"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
      >{{ "NavigationBar.Parts" | translate }}
      </eplan-bar-item>
      <eplan-bar-item
        *eplanBarItem
        eIcon="ti-user"
        routerLink="/users"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
      >{{ "NavigationBar.Users" | translate }}
      </eplan-bar-item>
      <ng-container>
        <eplan-bar-item
          *eplanBarItem
          eIcon="fl-icon-part-inquiry"
          routerLinkActive="active"
          routerLink="/requestplatform"
          [routerLinkActiveOptions]="{ exact: false }"
        >{{ "NavigationBar.RequestPlatform" | translate }}
        </eplan-bar-item>
      </ng-container>
      <ng-container *ngIf="isTokenUsageVisible()">
        <eplan-bar-item
        *eplanBarItem
        tooltip="The token generation usage"
        >
          <div class="embedTrial">{{ tokenUsage }}</div>
        </eplan-bar-item>
      </ng-container>
    </eplan-nav-bar>

    <router-outlet eplanMasterDetailRight>
      <app-dashboard
        [hidden]="homeHidden"
        [loaded]="isHomeLoaded"
        *ngIf="authorizationService.isUserAuthorized$ | async"
      ></app-dashboard>
      <app-manufacturer
        [hidden]="manufacturerHidden"
        [loaded]="isManufacturerLoaded"
        *ngIf="authorizationService.isUserAuthorized$ | async"
      ></app-manufacturer>
      <app-parts
        [hidden]="partsHidden"
        [loaded]="isPartsLoaded"
        *ngIf="authorizationService.isUserAuthorized$ | async"
      ></app-parts>
      <app-users
        [hidden]="uHidden"
        [loaded]="isULoaded"
        *ngIf="authorizationService.isUserAuthorized$ | async"
      ></app-users>
    </router-outlet>
  </eplan-master-detail>
</eplan-layout>
