import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from "@angular/core";
import { State, BasicButtonState, FlyoutOptions } from "@eplan/flux";
import { GlobalSearchFilterService, CheckedGlobalSearchEntry } from "app/services/filters/global-search-filter.service";
import { Subscription } from "rxjs";
@Component({
  selector: "app-global-search-filter",
  templateUrl: "./global-search-filter.component.html",
  styleUrls: ["./global-search-filter.component.scss"]
})
export class GlobalSearchFilterComponent implements OnInit, OnDestroy, AfterViewInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild("gsDropDown", { static: false }) public gsDropDown: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild("gsInput") gsInput: any;

  flyoutOptions: FlyoutOptions = {};
  public searchColorEState: BasicButtonState = State.DEFAULT;
  public isSearchControlEmpty: boolean = true;
  private isSearchInputActive: boolean = false;

  private searchSubscription: Subscription | undefined;
  private appliedFiltersSubscription: Subscription | undefined;
  private foundEntriesSubscription: Subscription | undefined;

  private dropDownShown: boolean = false;

  constructor(public globalSearchFilterService: GlobalSearchFilterService) { }
  public ngAfterViewInit() {
    this.updateFlyout();
  }

  private updateFlyout() {
    if (this.gsInput && this.gsInput.hostElement) {
      this.flyoutOptions = {
        container: "parent",
        popperOptions: {
          placement: "bottom-start",
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [-Number(this.gsInput.hostElement.nativeElement.offsetWidth), 0],
              },
            },
          ],
        },
      };
    }
  }

  public ngOnInit() {
    this.updateSearchColorScheme([]);
    this.searchSubscription = this.globalSearchFilterService.selectedText$.subscribe(
      (value) => {
        this.isSearchControlEmpty = value.length === 0;
      }
    );
    this.appliedFiltersSubscription = this.globalSearchFilterService.appliedFilters$.subscribe(
      appliedFilters => {
        this.updateSearchColorScheme(appliedFilters);
        this.showPopup();
      });

    this.foundEntriesSubscription = this.globalSearchFilterService.foundEntries$.subscribe(
      () => {
        this.showPopup();
      });
  }

  public ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
    this.appliedFiltersSubscription?.unsubscribe();
    this.foundEntriesSubscription?.unsubscribe();
  }

  public searchInputChanged(value: string): void {
    if (value.length === 0) {
      this.closeSearchResultDropdown();
    }
    this.globalSearchFilterService.selectedText$.next(value);
  }

  public updateSearchColorScheme(appliedFilters: CheckedGlobalSearchEntry[]): void {
    this.searchColorEState = appliedFilters.length > 0 ? State.PRIMARY : State.DEFAULT;
  }

  public clearSearch() {
    this.globalSearchFilterService.clearText();
  }

  public searchInputFocusEventOccurred() {
    this.isSearchInputActive = true;
  }

  public searchInputBlurEventOccurred() {
    this.isSearchInputActive = false;
  }

  public toggleSearchResultDropdownMenu() {
    this.dropDownShown = !this.dropDownShown;
  }

  public showPopup() {
    if (this.gsDropDown && this.isSearchInputActive) {
      this.updateFlyout();
      if ((this.globalSearchFilterService.foundEntries.length === 0 && this.dropDownShown === true) ||
        (this.globalSearchFilterService.foundEntries.length > 0 && this.dropDownShown === false)) {
        this.gsDropDown.toggleDropdown({});
      }
    }
  }

  private closeSearchResultDropdown() {
    if (this.dropDownShown) {
      this.gsDropDown.toggleDropdown({});
    }
  }

}
