import { Injectable, ErrorHandler, Injector } from "@angular/core";
import { HeaderNotificationService } from "./header-notification.service";
import { LoggerService } from "./logger.service";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root"
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(
    private injector: Injector,
    private logger: LoggerService
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public handleError(error: any) {
    this.logger.Error(error);
    if (error && !environment.production) {
      const notificationService = this.injector.get(HeaderNotificationService);
      if (notificationService) {
        notificationService.error(Date.now().toString(), error.message);
      }
    }
  }
}
