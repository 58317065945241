import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { version } from "./app-version";
import {
  APPS_URI,
  IDENTITYSERVICE_URI,
  NOTIFICATIONSERVICE_URI,
  REPORTCENTER_URI,
  REPORTCENTERSERVICE_URI,
  USER_PREFERENCES_URI,
  EPLAN_CUSTOMERMANAGEMENT_URI,
  MYACCOUNT_URI,
  EDPREQUESTPROCESS_URI,
  GOTO_SHORT_URI,
  DOWNLOADPORTAL_URI,
  MYACCOUNTSERVICE_URI,
  ENTITLEMENT_SESSION_URI,
  ORGANIZATION_API_URI,
  USER_PROFILE_URI,
  APP_FRAME_URI,
  IDENTITY_URI
} from "@eplan/ngx-configuration-service";

import {
  CS11_APP_ENVIRONMENT_COMPLETE,
  CS11_APP_ENVIRONMENT_EXTERNAL,
} from "app/services/injection-tokens";
import { EplanWindow } from "app/models/external-libs/eplan-window";

const disableConsole = () => {
  /* eslint-disable no-console */
  console.log = () => { /* Disable console logs. */ };
  console.error = () => { /* Disable console logs. */ };
  console.warn = () => { /* Disable console logs. */ };
  console.info = () => { /* Disable console logs. */ };
  console.trace = () => { /* Disable console logs. */ };
  console.time = () => { /* Disable console logs. */ };
  console.timeEnd = () => { /* Disable console logs. */ };
  /* eslint-enable no-console */
};

const eplanWindow = window as EplanWindow;

if (version) {
  const versionInfo = environment.production ? version : version + ".debug";
  eplanWindow.appVersion = versionInfo;
  if (!environment.production) {
    // eslint-disable-next-line no-console
    console.log("appVersion: ", versionInfo);
  }
}

if (environment.production) {
  enableProdMode();
  disableConsole();
}

platformBrowserDynamic([
  { provide: IDENTITYSERVICE_URI, useValue: eplanWindow.services.identityService },
  { provide: REPORTCENTER_URI, useValue: eplanWindow.services.reportCenter },
  { provide: REPORTCENTERSERVICE_URI, useValue: eplanWindow.services.reportCenterService },
  { provide: APPS_URI, useValue: eplanWindow.services.apps },
  { provide: NOTIFICATIONSERVICE_URI, useValue: eplanWindow.services.notificationService },
  { provide: USER_PREFERENCES_URI, useValue: eplanWindow.services.userPreferences },
  { provide: EPLAN_CUSTOMERMANAGEMENT_URI, useValue: eplanWindow.services.customerManagement },
  { provide: MYACCOUNT_URI, useValue: eplanWindow.services.myAccount },
  { provide: EDPREQUESTPROCESS_URI, useValue: eplanWindow.services.requestPlatform },
  { provide: GOTO_SHORT_URI, useValue: eplanWindow.services.gotoShortUrl },
  { provide: DOWNLOADPORTAL_URI, useValue: eplanWindow.services.downloadPortal },
  { provide: MYACCOUNTSERVICE_URI, useValue: eplanWindow.services.myAccountService },
  { provide: ENTITLEMENT_SESSION_URI, useValue: eplanWindow.services.entitlementSession },
  { provide: ORGANIZATION_API_URI, useValue: eplanWindow.services.organization },
  { provide: USER_PROFILE_URI, useValue: eplanWindow.services.userprofile },
  { provide: APP_FRAME_URI, useValue: eplanWindow.services.appFrame },
  { provide: IDENTITY_URI, useValue: eplanWindow.services.identity },
  { provide: CS11_APP_ENVIRONMENT_COMPLETE, useValue: eplanWindow.configuration.environment },
  { provide: CS11_APP_ENVIRONMENT_EXTERNAL, useValue: eplanWindow.configuration.environmentExternal },
]).bootstrapModule(AppModule);
