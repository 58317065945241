import { Component } from "@angular/core";
import { EDSFilterService } from "app/services/filters/eds-filter.service";

@Component({
  selector: "app-eds-filter",
  templateUrl: "./eds-filter.component.html",
  styleUrls: ["./eds-filter.component.scss"]
})
export class EdsFilterComponent {

  constructor(public edsFilterService: EDSFilterService) { }
}
