<div
  class="
    drop
    h-100
    d-flex
    flex-column
    justify-content-center
    align-items-center
  "
>
  <div class="text-center">
    <h3>
      {{ "Auth.NotAuthorizedTitle" | translate }}
      <br /><small> {{ "Auth.NotAuthorizedInfo" | translate }}</small>
      <i aria-hidden="true" class="fa fa-exclamation text-danger"></i>
    </h3>
  </div>

  <div class="row">
    <div class="col-12 text-center">
      <h1 class="pt-4 pb-3">
        <span class="ti-lock text-primary"></span>
      </h1>
      <button eplanButton id="signin-btn" (click)="signIn()">
        {{ "Auth.NotAuthenticatedSignIn" | translate }}
      </button>
    </div>
  </div>
</div>
