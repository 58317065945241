<eplan-bs-date-range-picker
  *ngIf="pickerEnabled"
  [(ngModel)]="dates"
  [bsDatePickerConfig]="bsDatePickerConfig"
  [dateRanges]="dateRanges"
  (ngModelChange)="onValueChange($event)"
  [eState]="currentState"
>
</eplan-bs-date-range-picker>
<eplan-bs-date-range-picker
  *ngIf="!pickerEnabled"
  [(ngModel)]="dates"
  [dateRanges]="dateRanges"
  [bsDatePickerConfig]="bsDatePickerConfig"
>
</eplan-bs-date-range-picker>
