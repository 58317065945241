import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DatePeriodFilterService } from "app/services/filters/date-period-filter.service";
import { environment } from "environments/environment";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { Subscription } from "rxjs";
import { getSpecificDate, GetSpecificDateEnum, getLastMonths } from "./date-range-filter-helper";
import { BasicState, State } from "@eplan/flux";

export interface DateRange {
  rangeTranslationId: string;
  /**
   * Date[0] is start date and Date[1] is end date.
   */
  dateRange: Date[];
}

const START_YEAR = 2001;

@Component({
  selector: "app-date-range-filter",
  templateUrl: "./date-range-filter.component.html",
  styleUrls: ["./date-range-filter.component.scss"]
})
export class DateRangeFilterComponent implements OnInit, OnDestroy {
  private datePeriodSubscription: Subscription | undefined;
  private languageSubscription: Subscription | undefined;

  public pickerEnabled: boolean = true;
  public disabled: boolean = false;
  public readonly: boolean = false;
  public dates: Date[] = [];
  public maxDate: Date = getSpecificDate(GetSpecificDateEnum.today);
  public minDate: Date = getSpecificDate(GetSpecificDateEnum.today);
  public dateRanges: DateRange[] = [];
  public currentState: BasicState = State.DEFAULT;
  public bsDatePickerConfig: Partial<BsDatepickerConfig> = {};

  constructor(
    private translateService: TranslateService,
    public datePeriodFilterService: DatePeriodFilterService
  ) {
    const today: Date = getSpecificDate(GetSpecificDateEnum.today);
    this.maxDate.setUTCDate(this.maxDate.getUTCDate());

    if (environment.onlyLastTwelveMonths) {
      this.minDate.setUTCFullYear(today.getUTCFullYear() - 1);
      this.minDate.setUTCDate(1);
    } else {
      this.minDate.setUTCFullYear(START_YEAR);
      this.minDate.setUTCMonth(0); // January
      this.minDate.setUTCDate(1);
    }

    this.setBsDatePickerConfig();
  }

  public ngOnInit(): void {
    const fromNgbDate: Date | undefined = this.datePeriodFilterService.fromDateState;
    const toNgbDate: Date | undefined = this.datePeriodFilterService.toDateState;
    if (fromNgbDate && toNgbDate) {
      this.dates = [fromNgbDate, toNgbDate];
    }

    this.datePeriodSubscription = this.datePeriodFilterService.selectedDatePeriod$?.subscribe(selected => {
      if (selected?.default && selected?.range) {
        const { from, to } = selected.range;
        this.dates = [from, to];
      }
    });

    this.languageSubscription = this.translateService.onLangChange.subscribe(() => {
      this.pickerEnabled = false;
      // refresh date picker workaround
      const TIME_OUT = 0;
      setTimeout(() => {
        this.pickerEnabled = true;
      }, TIME_OUT);
    });
  }

  private setBsDatePickerConfig() {
    this.dateRanges = [
      {
        dateRange: [getSpecificDate(GetSpecificDateEnum.firstDayOfCurrentMonth), getSpecificDate(GetSpecificDateEnum.today)
        ],
        rangeTranslationId: "Filters.CalculatedPeriodType.CurrentCalendarMonth"
      },
      {
        dateRange: [getSpecificDate(GetSpecificDateEnum.firstDayOfCurrentYear),
        getSpecificDate(GetSpecificDateEnum.today)],
        rangeTranslationId: "Filters.CalculatedPeriodType.CurrentCalendarYear"
      },
      {
        dateRange: [getSpecificDate(GetSpecificDateEnum.lastFourWeeks),
        getSpecificDate(GetSpecificDateEnum.today)],
        rangeTranslationId: "Filters.CalculatedPeriodType.LastFourWeeks"
      },
      {
        dateRange: [...getLastMonths(1)],
        rangeTranslationId: "Filters.CalculatedPeriodType.LastCalendarMonth"
      },
      {
        dateRange: [...getLastMonths(6)],
        rangeTranslationId: "Filters.CalculatedPeriodType.LastSixCalendarMonths"
      }, {
        dateRange: [...getLastMonths(12)],
        rangeTranslationId: "Filters.CalculatedPeriodType.LastTwelveCalendarMonths"
      }
    ];

    this.bsDatePickerConfig = {
      adaptivePosition: true,
      useUtc: true,
      isAnimated: true,
      showPreviousMonth: false,
      customRangeButtonLabel: this.translateService.instant("Filters.CalculatedPeriodType.UserDefined"),
      maxDate: this.maxDate,
      minDate: this.minDate
    };
  }

  public ngOnDestroy(): void {
    this.datePeriodSubscription?.unsubscribe();
    this.languageSubscription?.unsubscribe();
  }

  public onValueChange(value: Date[]): void {
    if (isNaN(value?.[0]?.getDate()) || isNaN(value?.[1]?.getDate())) {
      this.currentState = State.DANGER;
      return;
    }

    const fromDate = value[0];
    const toDate = value[1];
    const fromTimeUTC = fromDate.getTime();
    const toTimeUTC = toDate.getTime();

    if (fromTimeUTC > toTimeUTC || fromDate.getUTCFullYear() < START_YEAR || toDate.getUTCFullYear() < START_YEAR) {
      this.currentState = State.DANGER;
      return;
    }

    this.currentState = State.DEFAULT;

    this.datePeriodFilterService.updateDate(fromDate, toDate);
    this.datePeriodFilterService.fromToDateSelected(fromDate, toDate);
  }
}
