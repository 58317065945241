export enum StorageKeys {
  datePeriodKeyPeriod = "filters.date-period.period",
  datePeriodKeyFrom = "filters.date-period.from",
  datePeriodKeyTo = "filters.date-period.to",

  EDSKeyEds = "filters.eds.eds",

  productGroupTreeKey = "filters.product-group-tree.items",
  manufacturerCatalogKey = "filters.manufacturer-catalog.items",

  favoriteManufacturersKey = "filters.manufacturer.favorites",
  selectedManufacturersKey = "filters.manufacturer.selected",

  regionKeyRegions = "filters.region.list",

  dataExportDefaultExtension = "data-export-default-extension",

  globalSearchKeyApplied = "filters.global-search.applied",

  bookmarkNamesLastSelected = "bookmark-names-last-selected"
}
