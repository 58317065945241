import { Injectable } from "@angular/core";

export class BrowserNameAndVersion {
  public name?: string;
  public version?: number;
}

@Injectable()
export class BrowserService {

  private supportedBrowsers: BrowserNameAndVersion[] = [{ name: "Chrome", version: 55 },
  { name: "Edge", version: 15 },
  { name: "Safari", version: 10 }
  ];

  public isCefSharp(): boolean {
    if (Object.prototype.hasOwnProperty.call(window, "cefsharp_CreatePromise")) {
      return true;
    }
    return false;
  }

  public getBrowserInfo(): string {
    let browserInfo = this.getBrowserNameVersion().join(" ");

    const additionalInfos: string[] = [];
    if (this.isCefSharp()) {
      additionalInfos.push("cef");
    }
    if (Object.prototype.hasOwnProperty.call(window, "uploadservice")) {
      additionalInfos.push("p8");
    }
    if (additionalInfos.length > 0) {
      browserInfo += " (" + additionalInfos.join("/") + ")";
    }

    return browserInfo;
  }

  public getBrowserNameVersion(): string[] {
    let result: string[] = [];
    const userAgent = navigator.userAgent;
    const appName = navigator.appName;
    const appVersion = navigator.appVersion;

    let browserInfo = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    let browserVersion;
    if (/trident/i.test(browserInfo[1])) {
      browserVersion = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      result = ["IE", browserVersion[1] || ""];
      return result;
    }
    if (browserInfo[1] === "Chrome") {
      browserVersion = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (browserVersion != null) {
        // return browserVersion.slice(1).join(" ").replace("OPR", "Opera");
        result = browserVersion.slice(1);
        result[0] = result[0].replace("OPR", "Opera");
        return result;
      }
    }
    browserInfo = browserInfo[2] ? [browserInfo[1], browserInfo[2]] : [appName, appVersion, "-?"];
    browserVersion = userAgent.match(/version\/(\d+)/i);
    if ((browserVersion) != null) {
      browserInfo.splice(1, 1, browserVersion[1]);
    }
    return browserInfo;
  }

  public isBrowserSupported(): boolean {
    let supported = false;
    const nameAndVersion = this.getBrowserNameVersion();
    if (nameAndVersion && nameAndVersion.length > 1) {
      const name = nameAndVersion[0];
      const version: number = parseFloat(nameAndVersion[1]);
      for (const browser of this.supportedBrowsers) {
        if (name === browser.name) {
          if (browser.version && version >= browser.version) {
            supported = true;
            break;
          }
        }
      }
    }
    return supported;
  }

  public getSupportedBrowsers(): BrowserNameAndVersion[] {
    return this.supportedBrowsers.slice();
  }

  public getSupportedBrowsersAsString(): string {
    let supportedBrowsersString = "";
    const supportedBrowsers = this.getSupportedBrowsers();
    for (const browser of supportedBrowsers) {
      if (supportedBrowsersString.length > 0) {
        supportedBrowsersString += ", ";
      }
      supportedBrowsersString += browser.name + " " + browser?.version?.toString();
    }
    return supportedBrowsersString;
  }

}
