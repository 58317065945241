<ng-template #erpLeaf class="erp-flex" let-item>
  <eplan-checkbox
    class="erp-checkbox m-0 p-1 pl-2"
    [(ngModel)]="item.checked"
    (ngModelChange)="productGroupTreeFilterService.onItemChanged(item)"
    [disabled]="disabled"
  >
    <span class="pl-2 text-truncate erp-margin-right">
      {{ item.translateId }}
    </span>
    <span
      *ngIf="item.hasChildren"
      class="px-2 ti-angle-right icon erp-align-right"
      (click)="
        productGroupTreeFilterService.goToDirectory(item);
        $event.preventDefault();
        $event.stopImmediatePropagation()
      "
    ></span>
  </eplan-checkbox>
</ng-template>

<ng-template #erpBackTemplate let-back>
  <span class="px-2 ti-angle-left"></span>
  <span class="text-truncate erp-back-margin-right">
    {{ back.translateId | translate }}
  </span>
</ng-template>

<eplan-dropdown>
  <button eplanButton eplanDropdownToggle [eState]="eState">
    {{ "Filters.ProductGroupTree" | translate }}
  </button>
  <eplan-drilldown
    [itemTemplateRef]="erpLeaf"
    [backTemplateRef]="erpBackTemplate"
    [eItems]="productGroupTreeFilterService.currentItems"
    [parent]="productGroupTreeFilterService.currentParent"
    [itemPropertyChildren]="childrenProp"
    (backClick)="productGroupTreeFilterService.goToParentDirectory($event)"
  ></eplan-drilldown>
</eplan-dropdown>
