import { Injectable } from "@angular/core";
import { HostService } from "./host.service";

@Injectable()
export class UrlService {
  public readonly version: string = "v1";

  constructor(private hostService: HostService) { }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // Common Urls
  /////////////////////////////////////////////////////////////////////////////////////////////////

  public getIdentityServiceUrl(): string {
    return this.hostService.getIdentityServiceServer();
  }

  public getAppsUrl(): string {
    return `${this.hostService.getAppsServer()}`;
  }

  public getNotificationServiceUrl(): string {
    return `${this.hostService.getNotificationServiceServer()}`;
  }

  public getRequestPlatfromUrl(): string {
    return `${this.hostService.getRequestPlatformServer()}`;
  }
}
