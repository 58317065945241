import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { PBIReportResolverService } from "app/services/pbireportresolver.service";
import { ReportsService } from "app/services/reports.service";
import { DataExportType, DataExportItem, dataExports } from "app/services/data-export/DataExportType";
import { SplitAction, State, BasicButtonState } from "@eplan/flux";
import { StorageService } from "app/services/storage/storage.service";
import { StorageKeys } from "app/models/storageKeys";
import { Blade } from "app/models/blade";
import { Subscription } from "rxjs";
import { ReportDedicatedCapacityStatus } from "app/models/reportDedicatedCapacityStatus";
import { ActiveBladeService } from "app/services/blade/active-blade.service";

const EXPORT_BUTTON_BLOCK_MS = 5000;

export interface SplitDataExportItem extends SplitAction {
  dataExportItem: DataExportItem;
}

@Component({
  selector: "app-data-export",
  templateUrl: "./data-export.component.html",
  styleUrls: ["./data-export.component.scss"]
})
export class DataExportComponent implements OnInit, OnDestroy {
  private supportedFileFormats: DataExportType[] = [DataExportType.PDF, DataExportType.PNG, DataExportType.PPTX];
  private exportTimerId: null | ReturnType<typeof setTimeout> = null;
  private reportStatusSubscription: Subscription | undefined;
  private dedicatedCapacitySubscription: Subscription | undefined;
  private activeBladeServiceSubscription: Subscription | undefined;
  private reportRequestorSubscription: Subscription | undefined;
  private reportInProgressSubscription: Subscription | undefined;
  private onDedicatedCapacity: boolean = false;
  public exportInProgress: boolean = false;
  public dataExportList: SplitDataExportItem[] = [];
  public isDisabled: boolean = true;
  public eState: BasicButtonState = State.DEFAULT;

  @Input() public blade: Blade | undefined;

  constructor(
    private pbiReportResolverService: PBIReportResolverService,
    private reportsService: ReportsService,
    private activeBladeService: ActiveBladeService,
    private storageService: StorageService) { }

  public ngOnInit() {
    let defaultExtension = this.storageService.getDataFromStorage(StorageKeys.dataExportDefaultExtension);
    if (defaultExtension === null) {
      defaultExtension = "PDF";
    }
    dataExports.forEach(item => {
      if (this.supportedFileFormats.findIndex(entry => entry === item.type) !== -1) {
        const splitItem: SplitDataExportItem = {
          dataExportItem: item,
          translationId: item.nameID,
          isDefaultAction: item.extension === defaultExtension
        };
        this.dataExportList.push(splitItem);
      }
      this.dataExportList.sort((a, b) => a.dataExportItem.extension === defaultExtension ?
        -1 : b.dataExportItem.extension === defaultExtension ? 1 : -1);
    });

    this.updateReportStatus();
    this.updateActiveBlade();
    this.updateExportInProgress();
  }

  public ngOnDestroy(): void {
    this.unsubscribeDedicatedCapacity();
    this.unsubscribeReportStatus();
    this.unsubscribeActiveBlade();
    this.unsubscribeExportInProgress();
    if (this.reportRequestorSubscription) {
      this.reportRequestorSubscription.unsubscribe();
    }
  }

  public onClicked(item: SplitAction) {
    const splitItem = item as SplitDataExportItem;
    this.clearExportTimer();

    this.isDisabled = true; // prevents from clicking export multiple times
    this.exportTimerId = setTimeout(() => {
      this.isDisabled = false; // unlocking export button after EXPORT_BUTTON_BLOCK_MS time
    }, EXPORT_BUTTON_BLOCK_MS);

    const pbiReportService = this.pbiReportResolverService.get(this.blade);
    if (pbiReportService) {
      pbiReportService.requestReport(splitItem.dataExportItem);
    }

    this.storageService.saveDataToStorage(StorageKeys.dataExportDefaultExtension, splitItem.dataExportItem.extension);
  }

  private clearExportTimer(): void {
    if (this.exportTimerId !== null) {
      clearTimeout(this.exportTimerId);
      this.exportTimerId = null;
    }
  }

  private updateState(): void {
    this.isDisabled = !this.onDedicatedCapacity || this.exportInProgress;
  }

  private updateSelectedReportOnDedicatedCapacity() {
    this.unsubscribeDedicatedCapacity();

    const pbiReportService = this.pbiReportResolverService.get(this.blade);
    if (pbiReportService) {
      const reportId = pbiReportService.getReportId();
      if (reportId) {
        this.dedicatedCapacitySubscription = this.reportsService
          .getReportDedicatedCapacityStatus(reportId).subscribe(
            (dedicatedCapacityStatus: ReportDedicatedCapacityStatus) => {
              this.onDedicatedCapacity = dedicatedCapacityStatus.isOnDedicatedCapacity;
              this.updateState();
            });
      }
    }
  }

  private unsubscribeDedicatedCapacity(): void {
    this.dedicatedCapacitySubscription?.unsubscribe();
  }

  private updateReportStatus(): void {
    this.unsubscribeReportStatus();

    const pbiReportService = this.pbiReportResolverService.get(this.blade);
    if (pbiReportService) {
      this.reportStatusSubscription = pbiReportService.reportStatus$.subscribe(
        status => {
          if (status && status === "loaded") {
            this.updateSelectedReportOnDedicatedCapacity();
          }
        });
    }
  }

  private unsubscribeReportStatus(): void {
    if (this.reportStatusSubscription) {
      this.reportStatusSubscription.unsubscribe();
    }
  }

  private updateExportInProgress(): void {
    this.unsubscribeExportInProgress();

    const pbiReportService = this.pbiReportResolverService.get(this.blade);
    if (pbiReportService) {
      this.reportStatusSubscription = pbiReportService.exportInProgress$.subscribe(
        exportInProgress => {
          this.exportInProgress = exportInProgress;
          this.updateState();
        });
    }
  }

  private unsubscribeExportInProgress(): void {
    if (this.reportInProgressSubscription) {
      this.reportInProgressSubscription.unsubscribe();
    }
  }

  private updateActiveBlade() {
    this.activeBladeServiceSubscription = this.activeBladeService.bladeState$.subscribe(bladeState => {
      if (bladeState && bladeState.blade === this.blade && bladeState.active) {
        this.updateSelectedReportOnDedicatedCapacity();
      }
    });
  }

  private unsubscribeActiveBlade() {
    if (this.activeBladeServiceSubscription) {
      this.activeBladeServiceSubscription.unsubscribe();
    }
  }
}
