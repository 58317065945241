import { DateRange } from "./dateRange";
import { ManufacturerCatalogFilter } from "./filters/manufacturerCatalogFilter";

export enum FilterDateType {
  USER_DEFINED,
  ALL
}

export enum FilterAction {
  SELECT_ALL,
  SELECT_ITEM
}

export interface DateRangeFilter {
  type: FilterDateType;
  range?: DateRange;
}

export interface ProductGroupFilter {
  name: string;
  ptgID?: string;
  pgID?: string;
}

export interface EDSFilter {
  enabled: boolean;
}

export interface PartFilter {
  name: string;
}

export interface Country {
  name: string;
  code: string;
}

export interface RegionFilter {
  name: string;
  countries: Country[];
}

export interface ManufacturerFilter {
  code: string;
}

export interface Visual {
  reset?: boolean;
}

export interface ReportConfig {
  visuals?: Visual;
  code: string;
  page?: string;
  category?: string;
  filters?: ReportFilters;
}

export interface ReportFilters {
  dateRange?: DateRangeFilter;
  genericProductGroups?: ProductGroupFilter[];
  productGroups?: ProductGroupFilter[];
  manufacturerCatalogFilters?: ManufacturerCatalogFilter[];
  eds?: EDSFilter;
  parts?: PartFilter[];
  regions?: RegionFilter[];
  manufacturer?: ManufacturerFilter;
}
export declare interface ReportFilterService {
  isChanged(): void;
}
