import { Injectable, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ReportFilterService, RegionFilter, FilterAction } from "app/models/reportFilters";
import { BehaviorSubject, Subscription } from "rxjs";
import { predefinedRegions } from "./regions";
import { StorageService } from "../storage/storage.service";
import { StorageKeys } from "app/models/storageKeys";

export interface RegionFilterBatch {
  filters: RegionFilter[];
  allSelected: boolean;
}

export interface CheckedRegionFilter extends RegionFilter {
  action: FilterAction;
  checked: boolean;
}

export interface StoredRegion {
  name: string;
}
@Injectable({
  providedIn: "root"
})
export class RegionFilterService implements OnDestroy, ReportFilterService {
  public entries: CheckedRegionFilter[];

  public selectedRegions$: BehaviorSubject<RegionFilterBatch> = new BehaviorSubject<RegionFilterBatch>({filters: [], allSelected: false});
  private selectAllItem: CheckedRegionFilter;
  private languageSubscription: Subscription;

  constructor(private storageService: StorageService, private translateService: TranslateService) {
    this.selectAllItem = {
      name: translateService.instant("Filters.SelectAll"),
      countries: [],
      action: FilterAction.SELECT_ALL,
      checked: false
    };
    this.entries = this.toCheckedRegionFilter(predefinedRegions);
    this.initalRegionFilterState();

    this.languageSubscription = this.translateService.onLangChange.subscribe(() => {
      this.selectAllItem.name = this.translateService.instant("Filters.SelectAll");
    });
  }

  public ngOnDestroy() {
    this.languageSubscription.unsubscribe();
  }

  private initalRegionFilterState(): void {
    const storedRegions: StoredRegion[] | null = this.getRegionsLastSelected();
    this.entries = this.toCheckedRegionFilter(predefinedRegions);
    if (storedRegions && storedRegions.length > 0) {
      storedRegions.forEach(storedRegion => {
        const entry = this.entries.find(item => storedRegion.name === item.name);
        if (entry) {
          entry.checked = true;
        }
      });
    }
    const selected = this.getSelectedRegions();
    this.nextSelectedGroup(selected);
    this.updateSelectAllItemState(selected);
  }

  private saveRegionSelected(regions: RegionFilter[]): void {
    const storeRegions: StoredRegion[] = regions.map(item => ({ name: item.name }));
    this.storageService.saveDataToStorage(StorageKeys.regionKeyRegions, storeRegions);
  }

  private clearRegionsLastSelected(): void {
    this.storageService.deleteDataFromStorage(StorageKeys.regionKeyRegions);
  }

  private getRegionsLastSelected(): StoredRegion[] | null {
    return this.storageService.getDataFromStorage(StorageKeys.regionKeyRegions);
  }

  public selectAll(value: boolean) {
    let changed: boolean = false;
    for (const filter of this.entries) {
      if (filter.checked !== value) {
        filter.checked = value;
        changed = true;
      }
    }
    if (changed) {
      this.nextSelectedGroup(this.getSelectedRegions());
    }
  }

  public clearSelectedRegions(): void {
    this.selectAll(false);
  }

  public isModified() {
    return !this.entries.every(item => item.checked === true)
      && this.entries.some(item => item.checked === true);
  }

  public nextSelectedGroup(selected: RegionFilter[]) {
    const batch: RegionFilterBatch = {
      filters: selected,
      allSelected: this.entries.every(item => item.checked === true)
    };
    this.selectedRegions$.next(batch);
  }

  public getSelectedRegions(): RegionFilter[] {
    const result: RegionFilter[] = [];
    for (const filter of this.entries) {
      if (filter.checked && filter.action === FilterAction.SELECT_ITEM) {
        result.push(filter);
      }
    }
    return result;
  }

  public isChanged() {
    return this.getSelectedRegions().length > 0;
  }

  public isRegionChecked(filter: RegionFilter): boolean {
    const item = this.entries.find(value => value.name === filter.name);
    return item !== undefined && item.checked === true;
  }

  private updateSelectAllItemState(selected: RegionFilter[]) {
    this.selectAllItem.checked = selected.length === 0
      ? false : this.entries.every(
        item => (item.checked || item.action === FilterAction.SELECT_ALL));
  }

  public onFilterChanged(filter: CheckedRegionFilter): void {
    if (filter.action === FilterAction.SELECT_ALL) {
      this.selectAll(filter.checked);
      const selected = this.getSelectedRegions();
      this.saveRegionSelected(selected);
    } else {
      const selected = this.getSelectedRegions();
      this.updateSelectAllItemState(selected);
      this.nextSelectedGroup(selected);
      this.saveRegionSelected(selected);
    }
  }

  public reset() {
    this.clearRegionsLastSelected();
    this.entries.forEach(entry => (entry.checked = false));
    this.nextSelectedGroup([]);
  }

  public toCheckedRegionFilter(regions: RegionFilter[]): CheckedRegionFilter[] {
    const result: CheckedRegionFilter[] = [this.selectAllItem];
    regions.forEach(entry => result.push(Object.assign(entry,
      {
        action: FilterAction.SELECT_ITEM,
        checked: false
      }
    )));
    return result;
  }
}
