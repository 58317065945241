import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { HostService } from "./host.service";
import { BackendEndpointsErrorHandler } from "app/services/backend-endpoints-error-handler.service";
import { QueryManufacturer } from "app/models/queryManufacturer";
import { QueryProductGroup } from "app/models/queryProductGroup";
import { GlobalSearchEntry } from "app/models/globalSearchEntry";
import { ManufacturerCatalogFilter } from "app/models/filters/manufacturerCatalogFilter";
import { BACKEND_API_PATH } from "app/models/api";

const QUERY_API_PATH = BACKEND_API_PATH + "/query";
const PRODUCT_GROUP_CATALOG_API_PATH = BACKEND_API_PATH + "/db/product-group-catalog";
const MANUFACTURER_CATALOG_API_PATH = BACKEND_API_PATH + "/db/manufacturer-catalog";
const GLOBAL_SEARCH_API_PATH = BACKEND_API_PATH + "/globalsearch";

@Injectable()
export class QueryService {
  private backendURL: string;
  constructor(
    private httpClient: HttpClient,
    private hostService: HostService,
    private backendEndpointsErrorHandler: BackendEndpointsErrorHandler) {
    this.backendURL = this.hostService.getReportCenterServiceServer();
    }

  public getManufacturersList(category?: string): Observable<QueryManufacturer[]> {
    const url = this.backendURL + QUERY_API_PATH +
      "?request=get-manufacturers-list" + ((category === undefined) ? "" : ("&category=" + category));
    return this.httpClient.get<QueryManufacturer[]>(url)
      .pipe(catchError(this.backendEndpointsErrorHandler.notificationHandler.bind(this.backendEndpointsErrorHandler)));
  }

  public getProductGroups(language: string, levels?: string[]): Observable<QueryProductGroup[]> {
    const url = this.backendURL + PRODUCT_GROUP_CATALOG_API_PATH;
    let body = new HttpParams().set("language", language);
    if (levels !== undefined) {
      for (const item of levels) {
        body = body.append("levels", item);
      }
    }
    return this.httpClient.post<QueryProductGroup[]>(url, body,
      { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
      .pipe(catchError(this.backendEndpointsErrorHandler.notificationHandler.bind(this.backendEndpointsErrorHandler)));
  }

  public getManufacturerCatalog(
    language: string,
    manufacturerShortName: string | null,
    levels: string[]): Observable<ManufacturerCatalogFilter[]> {
    if (manufacturerShortName !== null) {
      const url = this.backendURL + MANUFACTURER_CATALOG_API_PATH;
      let body = new HttpParams().set("language", language)
        .set("manufacturer-short-name", manufacturerShortName);
      if (levels !== undefined) {
        for (const item of levels) {
          body = body.append("levels", item);
        }
      }
      return this.httpClient.post<ManufacturerCatalogFilter[]>(url, body,
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
        .pipe(catchError(this.backendEndpointsErrorHandler.notificationHandler.bind(
          this.backendEndpointsErrorHandler)));
    }

    return of(new Array<ManufacturerCatalogFilter>());
  }

  public findGlobalSearchEntries(searchQuery: string, limit: number, manCode: string | null): Observable<GlobalSearchEntry[]> {
    const url = this.backendURL + GLOBAL_SEARCH_API_PATH;
    let contentType = "application/x-www-form-urlencoded";

    if (this.hostService.isCNEnvironment()) {
        contentType = "text/plain";
    }

    const body = new HttpParams()
      .set("search-query", encodeURIComponent(searchQuery))
      .set("limit", limit.toString())
      .set("man-code", manCode ? encodeURIComponent(manCode) : "");
    return this.httpClient.post<GlobalSearchEntry[]>(url, body,
      { headers: { "Content-Type": contentType } })
      .pipe(catchError(this.backendEndpointsErrorHandler.notificationHandler.bind(
        this.backendEndpointsErrorHandler)));
  }
}
