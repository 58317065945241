import { Injectable } from "@angular/core";
import { Blade } from "app/models/blade";
import { PBIReportService } from "./pbireport.service";

@Injectable({
  providedIn: "root"
})
export class PBIReportResolverService {

  private map: Map<Blade, PBIReportService> = new Map<Blade, PBIReportService>();

  public set(blade: Blade, pbiReportService: PBIReportService) {
    this.map.set(blade, pbiReportService);
  }

  public get(blade: Blade | undefined): PBIReportService | undefined {
    return blade ? this.map.get(blade) : this.map.get(Blade.Home);
  }
}
