import { Inject, Injectable } from "@angular/core";
import {
  APPS_URI,
  IDENTITYSERVICE_URI,
  NOTIFICATIONSERVICE_URI,
  REPORTCENTER_URI,
  REPORTCENTERSERVICE_URI,
  MYACCOUNT_URI,
  EDPREQUESTPROCESS_URI,
} from "@eplan/ngx-configuration-service";
import {
  CS11_APP_ENVIRONMENT_COMPLETE,
  CS11_APP_ENVIRONMENT_EXTERNAL,
} from "app/services/injection-tokens";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable()
export class HostService {

  constructor(public httpClient: HttpClient,
    @Inject(CS11_APP_ENVIRONMENT_COMPLETE) private appEnvironment: string,
    @Inject(CS11_APP_ENVIRONMENT_EXTERNAL) private appEnvironmentExternal: string,
    @Inject(APPS_URI) private appsServer: string,
    @Inject(IDENTITYSERVICE_URI) private identityServiceServer: string,
    @Inject(NOTIFICATIONSERVICE_URI) private notificationServiceServer: string,
    @Inject(REPORTCENTER_URI) private reportCenterServer: string,
    @Inject(REPORTCENTERSERVICE_URI) private reportCenterServiceServer: string,
    @Inject(MYACCOUNT_URI) private myAccountUrl: string,
    @Inject(EDPREQUESTPROCESS_URI) private requestPlatfromServer: string,
  ) {
  }

  public async initialize(): Promise<boolean> {
    const completeEnvironment = this.appEnvironment.includes("topic") ? "devreview" : this.appEnvironment;
    if (completeEnvironment === "devreview") {
      if (this.reportCenterServiceServer.includes("topic")) {
        this.reportCenterServiceServer = this.reportCenterServiceServer.replace(/topic[0-9]+\./, "review.");
      } else if (!this.reportCenterServiceServer || this.reportCenterServiceServer.length === 0) {
        this.reportCenterServiceServer = environment.fallbackBackendApiUrl;
      }

      if (this.reportCenterServer.includes("topic")) {
        this.reportCenterServer = this.reportCenterServer.replace(/topic[0-9]+\./, "review.");
      } else if (!this.reportCenterServer || this.reportCenterServer.length === 0) {
        this.reportCenterServer = environment.fallbackLogosUrl;
      }
    }

    return true;
  }

  public getAppsServer(): string {
    return this.appsServer;
  }

  public getIdentityServiceServer(): string {
    return this.identityServiceServer;
  }

  public getNotificationServiceServer(): string {
    return this.notificationServiceServer;
  }

  public getReportCenterServiceServer(): string {
    if (environment.backendApiUrl.length > 0 ||
      !this.reportCenterServiceServer ||
      this.reportCenterServiceServer.length === 0) {
      return environment.backendApiUrl;
    }

    return this.reportCenterServiceServer;
  }

  public getManufacturerLogosServer(): string {
    return this.reportCenterServer;
  }

  public isTopicEnvironment(): boolean {
    return this.appEnvironmentExternal !== "prod" && this.appEnvironment.includes("topic");
  }

  public isLocalhostEnvironment(): boolean {
    return this.appEnvironmentExternal !== "prod" && environment.production === false;
  }

  public isProdOrPreprodEnvironment(): boolean {
    const env = this.appEnvironmentExternal.toUpperCase().trim();
    return env.includes("PROD");
  }

  public isCNEnvironment(): boolean {
    return this.appEnvironment.includes("cn");
  }

  private getURLParam(name: string): string {
    const regexp = new RegExp(`${name}=[a-zA-Z0-9]+`, "g");
    const params = regexp.exec(document.location.search.substring(1));
    return params && params.length > 0 ? params[0].substr(name.length + 1) : "";
  }

  public getMyAccountUrl() {
    return this.myAccountUrl;
  }

  public getRequestPlatformServer(): string {
    return `${this.requestPlatfromServer}/embed/manufacturer`;
  }
}
