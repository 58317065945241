import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ReportFilterService } from "app/models/reportFilters";
import { StorageService } from "../storage/storage.service";
import { StorageKeys } from "app/models/storageKeys";

const defaultCheckedValue: boolean = false;

interface EdsSelected {
  eds: boolean;
}

@Injectable({
  providedIn: "root"
})
export class EDSFilterService implements ReportFilterService {
  public edsChecked: boolean = false;
  public selected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private storageService: StorageService) {

    this.initialStateOfFilter();
  }

  public initialStateOfFilter(): void {
    const getEdsLastState: EdsSelected | null = this.storageService.getDataFromStorage<EdsSelected>(StorageKeys.EDSKeyEds);
    this.edsChecked = getEdsLastState ? getEdsLastState.eds : defaultCheckedValue;
    this.selected$.next(this.edsChecked);
  }

  public onEDSChanged(value: boolean): void {
    this.storageService.saveDataToStorage<EdsSelected>(StorageKeys.EDSKeyEds, { eds: value });
    this.selected$.next(value);
  }

  public reset() {
    this.storageService.saveDataToStorage<EdsSelected>(StorageKeys.EDSKeyEds, { eds: false });
    this.edsChecked = false;
  }
  public isChanged() {
    return this.edsChecked !== defaultCheckedValue;
  }
}
