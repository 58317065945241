<eplan-input-group>
  <input eplanInput #gsInput
    id="global-search-input"
    class="input-outline-{{ searchColorEState }} search-input--greater-width"
    [needClearButton]="true"
    type="search"
    [placeholder]="'Filters.SearchPlaceholder' | translate"
    [(ngModel)]="globalSearchFilterService.searchText"
    (ngModelChange)="searchInputChanged($event)"
    (focus)="searchInputFocusEventOccurred()"
    (blur)="searchInputBlurEventOccurred()"
    (click)="showPopup();"
  />
  <eplan-dropdown #gsDropDown [flyoutOptions]="flyoutOptions" (showDropdownMenuChange)="toggleSearchResultDropdownMenu()">
    <button
      id="button-dropdown"
      eplanButton
      eplanDropdownToggle
      [eState]="searchColorEState"
    ></button>
    <eplan-checkbox class="gs-checkbox"
      *ngFor="let entry of globalSearchFilterService.applied"
      [(ngModel)]="entry.checked"
      (ngModelChange)="globalSearchFilterService.onSuggestionClicked(entry)"
    >
      {{ entry.name }}
    </eplan-checkbox>
    <div class="gs-divider"></div>
    <eplan-checkbox class="gs-checkbox"
      *ngFor="let entry of globalSearchFilterService.suggestions"
      [(ngModel)]="entry.checked"
      (ngModelChange)="globalSearchFilterService.onSuggestionClicked(entry)"
    >
      {{ entry.name }}
    </eplan-checkbox>
  </eplan-dropdown>
</eplan-input-group>