import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { MainPath } from "app/models/app.routes.model";
import { AuthorizationService } from "./authorization.service";
import { Observable, tap } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AuthGuardService {
  constructor(private authorizationService: AuthorizationService, private router: Router) { }
  public canActivate(): Observable<boolean> {
    return this.authorizationService.isUserAuthorized$.pipe(
      tap(isUserAuthorized => {
        if (!isUserAuthorized) {
          this.router.navigateByUrl(MainPath.NOT_AUTHORIZED);
        }
      })
    );
  }
}

export function AuthGuard(): Observable<boolean> {
  return inject(AuthGuardService).canActivate();
}