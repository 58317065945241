import { ReportFilters } from "app/models/reportFilters";

export enum EplanAnalyticsUserAppliedFilterEventContext {
  HOME = "Home",
  MANUFACTURER = "Manufacturer",
  PARTS = "Parts",
  USERS = "Users"
}

export interface EplanAnalyticsUserAppliedFilterEvent {
  context: EplanAnalyticsUserAppliedFilterEventContext;
  filters: ReportFilters;
  manufacturer: string;
  isEplan?: boolean; // pro forma
}
