import { Component, ViewChild, ElementRef, Input } from "@angular/core";
import { ReportConfig } from "app/models/reportFilters";
import { ManufacturerFilterService } from "app/services/filters/manufacturer-filter.service";
import { EplanAnalyticsService } from "app/services/eplananalytics.service";
import { EplanAnalyticsUserAppliedFilterEventContext } from "app/models/eplanAnalyticsUserAppliedFilterEvent";
import { Blade } from "app/models/blade";
import { ePLANOrgs } from "app/models/eplanReportCode";
import { ActiveBladeService, BladeState } from "app/services/blade/active-blade.service";

const reportCategoryUsers = "users";
const reportCategoryUsersAllManufacturers = "usersallmanufacturers";

const bladeAnalyticsMap = new Map([
  [Blade.Users, EplanAnalyticsUserAppliedFilterEventContext.USERS]
]);

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"]
})

export class UsersComponent {
  @Input() public loaded: boolean = false;

  @ViewChild("reportViewer", { static: false }) private reportViewer: ElementRef | undefined;

  public reportCategories: string[] = [
    reportCategoryUsers,
    reportCategoryUsersAllManufacturers
  ];
  public reportConfig: ReportConfig | null = null;
  public currentBlade: Blade = Blade.Users;

  constructor(
    public manufacturerFilterService: ManufacturerFilterService,
    public eplanAnalyticsService: EplanAnalyticsService,
    private activeBladeService: ActiveBladeService) {

    this.activeBladeService.bladeState$.subscribe((bladeState: BladeState) => {
      if (bladeState.active && bladeState.blade === Blade.Users && this.reportConfig) {
        this.reportConfigChanged(this.reportConfig);
      }
    });
  }

  public reportConfigChanged(reportConfig: ReportConfig) {
    this.reportConfig = {
      visuals: reportConfig.visuals,
      code: reportConfig.code,
      page: Blade.Users,
      category: ePLANOrgs.includes(reportConfig.code) ? reportCategoryUsersAllManufacturers : reportCategoryUsers,
      filters: reportConfig.filters
    };
    this.sendEplanAnalyticsAppliedFilterEvent();
  }

  private sendEplanAnalyticsAppliedFilterEvent() {
    const analyticsContext = bladeAnalyticsMap.get(Blade.Users);
    if (analyticsContext && this.reportConfig) {
      this.eplanAnalyticsService.sendEplanAnalyticsAppliedFilterEvent(
        analyticsContext, this.reportConfig.filters, this.reportConfig.code);
    }
  }
}
