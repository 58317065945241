import { Component } from "@angular/core";
import { AuthorizationService } from "app/services/auth/authorization.service";

@Component({
  selector: "app-not-authorized",
  templateUrl: "./not-authorized.component.html"
})
export class NotAuthorizedComponent {
  constructor(private authorizationService: AuthorizationService) { }

  public signIn() {
    this.authorizationService.signInForce();
  }
}
