import { Component, Input } from "@angular/core";
import { Blade } from "app/models/blade";
import { EplanAnalyticsUserAppliedFilterEventContext } from "app/models/eplanAnalyticsUserAppliedFilterEvent";
import { ePLANOrgs } from "app/models/eplanReportCode";
import { ReportConfig } from "app/models/reportFilters";
import { EplanAnalyticsService } from "app/services/eplananalytics.service";
import { ManufacturerFilterService } from "app/services/filters/manufacturer-filter.service";


const reportCategoryManufacturerParts = "manparts";
const reportCategoryManufacturerPartsAllManufacturers = "manpartsallmanufacturers";

@Component({
  selector: "app-parts",
  templateUrl: "./parts.component.html",
  styleUrls: ["./parts.component.scss"]
})
export class PartsComponent {

  @Input() public loaded: boolean = false;

  public reportCategories: string[] = [
    reportCategoryManufacturerParts,
    reportCategoryManufacturerPartsAllManufacturers
  ];
  public reportConfig: ReportConfig = { visuals: undefined, code: "", category: undefined, filters: undefined };
  public Blade: typeof Blade = Blade;

  constructor(public manufacturerFilterService: ManufacturerFilterService,
    public eplanAnalyticsService: EplanAnalyticsService) { }

  public reportConfigChanged(reportConfig: ReportConfig) {
    this.reportConfig = {
      visuals: reportConfig.visuals,
      code: reportConfig.code,
      page: Blade.Parts,
      category: ePLANOrgs.includes(reportConfig.code)
        ? reportCategoryManufacturerPartsAllManufacturers
        : reportCategoryManufacturerParts,
      filters: reportConfig.filters
    };

    this.sendEplanAnalyticsAppliedFilterEvent();
  }

  private sendEplanAnalyticsAppliedFilterEvent() {
    this.eplanAnalyticsService.sendEplanAnalyticsAppliedFilterEvent(
      EplanAnalyticsUserAppliedFilterEventContext.PARTS,
      this.reportConfig.filters,
      this.reportConfig.code
    );
  }

}
