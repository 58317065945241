export enum GetSpecificDateEnum {
  firstDayOfCurrentMonth = "firstDayOfCurrentMonth",
  today = "today",
  firstDayOfCurrentYear = "firstDayOfCurrentYear",
  lastFourWeeks = "lastFourWeeks"
}

const CONVERT_MIN_TO_MILLISECONDS = 60000;

export const getLastMonths = (numberOfMonths: number): Date[] => {
  const d = new Date();
  const timeUTC = d.getTime() + (d.getTimezoneOffset() * CONVERT_MIN_TO_MILLISECONDS);
  const dateUTC = new Date(timeUTC);
  const lastXMonths = new Date(dateUTC.setMonth(dateUTC.getMonth() - numberOfMonths, 1));
  const currentMonth = new Date(getSpecificDate(GetSpecificDateEnum.today).setUTCMonth(getSpecificDate(GetSpecificDateEnum.today)
    .getMonth()));
  const lastDayOfMonth = new Date(currentMonth.setDate(0));
  return [lastXMonths, lastDayOfMonth];
};

export const getDefaultMonths = (numberOfMonths: number): Date[] => {
  const d = new Date();
  const timeUTC = d.getTime() + (d.getTimezoneOffset() * CONVERT_MIN_TO_MILLISECONDS);
  const dateUTC = new Date(timeUTC);
  const lastXMonths = new Date(dateUTC.setMonth(dateUTC.getMonth() - numberOfMonths, 1));
  return [lastXMonths, getSpecificDate(GetSpecificDateEnum.today)];
};

const DAYS = 28;

export const getSpecificDate = (dateType: GetSpecificDateEnum): Date => {
  const date = new Date();
  const timeUTC = date.getTime() + (date.getTimezoneOffset() * CONVERT_MIN_TO_MILLISECONDS);
  const dateUTC = new Date(timeUTC);
  if (dateType === "firstDayOfCurrentMonth") {
    const first = new Date(dateUTC.setDate(1));
    return first;
  } else if (dateType === "today") {
    return dateUTC;
  } else if (dateType === "firstDayOfCurrentYear") {
    return new Date(dateUTC.getFullYear(), 0, 1);
  } else if (dateType === "lastFourWeeks") {
    return new Date(dateUTC.setDate(new Date().getDate() - DAYS));
  }
  return dateUTC;
};
