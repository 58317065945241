import { Injectable } from "@angular/core";

interface IAppService {
  openApp(app: string): void;
  getAssemblyVersion(): string;
}

// "appservice" muss kleingeschrieben werden!
declare const appservice: IAppService;

@Injectable()
export class EplanPlattformService {
  private p8AppService: IAppService | undefined;

  constructor() {
    if (this.isAvailable()) {
      this.p8AppService = appservice;
    }
  }

  public isAvailable(): boolean {
    return typeof appservice !== "undefined";
  }

  public openApp(app: string) {
    if (this.p8AppService) {
      this.p8AppService.openApp(app);
    }
  }

  public getAssemblyVersion(): string {
    if (this.p8AppService) {
      return this.p8AppService.getAssemblyVersion();
    }
    return "";
  }
  public AppPlatformChecker() {
    if (this.getAssemblyVersion() === "") {
      return "browser";
    } else {
      return "p8";
    }
  }
}
