<div *ngIf="exportInProgress">
  <eplan-loader></eplan-loader>
</div>
<eplan-split-button
  *ngIf="!exportInProgress"
  [isPersistent]="true"
  [eplanDisabled]="isDisabled"
  [splitActions]="dataExportList"
  [eState]="eState"
  (performAction)="onClicked($event)"
>
</eplan-split-button>
