<eplan-dropdown>
  <button eplanButton eplanDropdownToggle [eState]="eState">
    {{ "Filters.Region" | translate }}
  </button>
  <eplan-checkbox class="item-container"
    [disabled]="disabled"
    *ngFor="let region of regionFilterService.entries"
    [(ngModel)]="region.checked"
    (ngModelChange)="regionFilterService.onFilterChanged(region)"
  >
    {{ region.name }}
  </eplan-checkbox>
</eplan-dropdown>
