import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { AuthorizationService } from "./authorization.service";
import { Observable, map, tap } from "rxjs";

/** Opposite of AuthGuardService.
 *
 * Informs whether "Not authorized" screen can be shown to user.
 * If user is authorized, redirection to main page is performed instead of showing "Not authorized".
 */
@Injectable({
  providedIn: "root"
})
@Injectable()
export class NoAuthGuardService {
  constructor(private authorizationService: AuthorizationService, private router: Router) { }
  public canActivate(): Observable<boolean> {
    return this.authorizationService.isUserAuthorized$.pipe(
      tap(isUserAuthorized => {
        if (isUserAuthorized) {
          this.router.navigateByUrl("/")
        }
      }),
      map(isUserAuthorized => !isUserAuthorized)
    );
  }
}

export function NoAuthGuard(): Observable<boolean> {
  return inject(NoAuthGuardService).canActivate();
}