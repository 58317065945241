import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { EMPTY } from "rxjs";
import { HeaderNotificationService } from "app/services/header-notification.service";
import { TranslateService } from "@ngx-translate/core";
import { LoggerService } from "./logger.service";
import { StatusCodes } from "http-status-codes";

interface BackendError {
  type: string;
  title: string;
  status: number;
  instance: string;
  detail: string;
}

@Injectable()
export class BackendEndpointsErrorHandler {
  constructor(
    private notificationService: HeaderNotificationService,
    private translateService: TranslateService,
    private logger: LoggerService) {
  }

  private commonHandler(error: HttpErrorResponse) {
    let message = "";
    if (window.navigator.onLine) {
      const backendError = error.error as BackendError;
      const title = backendError?.title ? this.translateService.instant(`BackendError.${backendError.title}`) :
        this.translateService.instant("BackendError.undefined");
      message = backendError?.status && Number.isInteger(backendError.status) ? title :
        this.translateService.instant("Status.noConnectionServer");
    } else {
      message = this.translateService.instant("Status.networkError");
    }
    this.notificationService.error(Date.now().toString(),
      message);
    this.logger.Error(message);
  }

  public notificationHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.logger.Error(error.type);
      this.notificationService.warn(Date.now().toString(),
        this.translateService.instant("Status.networkError"));
    } else {
      this.commonHandler(error);
    }

    return EMPTY;
  }

  public fileExportNotificationHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.logger.Error(error.type);
      this.notificationService.warn(Date.now().toString(),
        this.translateService.instant("Status.networkError"));
    } else {
      const backendError = error.error as BackendError;

      switch (backendError.status) {
        case StatusCodes.TOO_MANY_REQUESTS: {
          this.notificationService.error(Date.now().toString(),
            this.translateService.instant("StdReport.FailedExportsLimitReached"));
          this.logger.Error("StdReport.FailedExportsLimitReached");
          break;
        }

        case StatusCodes.INTERNAL_SERVER_ERROR:
        case StatusCodes.BAD_REQUEST:
        case StatusCodes.NOT_FOUND: {
          this.notificationService.error(Date.now().toString(),
            this.translateService.instant("StdReport.exportToFileFailed"));
          this.logger.Error("StdReport.exportToFileFailed");
          break;
        }

        default: {
          this.commonHandler(error);
        }
      }
    }

    return EMPTY;
  }

  public skipHandler() {
    return EMPTY;
  }
}
